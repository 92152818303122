import $ from 'jquery';
import whatInput from 'what-input';
import 'fullcalendar';

window.$ = $;

import Foundation from 'foundation-sites';

import 'tablesaw/dist/tablesaw.jquery';
import libs from './lib/dependancies';

window.libs = libs;

$(document).foundation();

var resources = {
    //Navigation
    "navTitle": "MV Gingen",
    "navMobileHeadline": "Menü",
    "nav1Main": "Verein",
    "nav1Sub1": "Über uns",
    "nav1Sub2": "Vorstand",
    "nav1Sub3": "Termine",
    "nav1Sub4": "Musikerheim",
    "nav2Main": "Orchester",
    "nav2Sub1": "Aktive Kapelle",
    "nav2Sub2": "Dirigent",
    "nav2Sub3": "Musiker",
    "nav3Main": "Jugend",
    "nav3Sub1": "Ausbildung",
    "nav3Sub2": "Jugendkapelle",
    "nav3Sub3": "Jugendgruppe",
    "nav3Sub4": "Blockflöten",
    "nav3Sub5": "Musikalische Früherziehung",
    "nav3Sub6": "Außermusikalische Aktivitäten",
    "nav4Main": "Bilder",
    "nav4Sub1": "2019",
    "nav4Sub2": "2018",
    "nav4Sub3": "2017",
    "nav4Sub4": "2020",
    "nav4Sub5": "2021",
    "nav4Sub6": "2022",
    "nav4Sub7": "2023",
    "nav4Sub8": "2024",
    "nav5Main": "Sponsoren",
    "nav6Main": "Intern",
    "nav7Main": "Kontakt",
    //Footer
    "footImpres": "Impressum",
    "footDP": "Datenschutzerklärung",
    "footBE": "Beitrittserklärung",
    //Impressum
    "impresHeadline": "Impressum",
    "impresSideInfo": "Angaben gemäß §5 TMG:",
    "impresP1": "Musikverein Gingen/Fils 1912 e.V.<br>Grabenstraße 95<br>73333 Gingen an der Fils",
    "impresP2": "   01514 0431569",
    "impresP3": "   kontakt@mvgingen.de",
    "impresP4Des": "Vertreten durch",
    "impresP4Str1": "Vorsitzender Markus Windstoßer",
    "impresP4Str2": "Stellvertretender Vorsitzender Jonas Steegmaier",
    "impresP5": "Eingetragen im Vereinsregister: VR 540120 beim Amtsgericht Ulm, Registergericht, Zeughausstraße 17, 89070 Ulm",
    "impresP6": "Steuernummer: 62100/01507",
    //Datenschutz
    "dataprotectionHeadline": "Datenschutzerklärung",
    "dataprotectionSideInfo": "Wir, der Musikverein Gingen 1912 e.V. (im Folgenden \"wir/unser(e)\"), nehmen den Schutz Ihrer persönlichen Daten sehr ernst und halten uns streng an alle geltenden Gesetze und Vorschriften zum Datenschutz, insbesondere an die Datenschutzgrundverordnung, (DSGVO), das Bundesdatenschutzgesetz (BDSG) und das Telemediengesetz (TMG). Die folgenden Erläuterungen geben Ihnen einen Überblick darüber, wie wir diesen Schutz sicherstellen und welche Daten wir zu welchem Zweck verarbeiten.",
    "dataprotectionP1Header": "1. Nutzungsdaten",
    "dataprotectionP1Str1": "Bei jedem Zugriff auf unsere Webseite und bei jedem Abruf einer Datei, werden automatisch über diesen Vorgang allgemeine Daten in einer Protokolldatei gespeichert. Die Speicherung dient ausschließlich systembezogenen und statistischen Zwecken (auf Grundlage von Art. 6 Abs. 1 Buchst. b) DSGVO), sowie in Ausnahmefällen zur Anzeige von Straftaten (auf Grundlage von Art. 6 Abs. 1 Buchst. e) DSGVO).",
    "dataprotectionP1Str2": "Eine Weitergabe der Daten an Dritte oder eine sonstige Auswertung findet nicht statt, es sei denn, es besteht eine gesetzliche Verpflichtung dazu (Art. 6 Abs. 1 Buchst. e) DSGVO).",
    "dataprotectionP1Str3Des": "Im Einzelnen wird über jeden Abruf folgender Datensatz gespeichert:",
    "dataprotectionP1Str3Con1": "Name der abgerufenen Datei",
    "dataprotectionP1Str3Con2": "Datum und Uhrzeit des Abrufs",
    "dataprotectionP1Str3Con3": "übertragene Datenmenge",
    "dataprotectionP1Str3Con4": "Meldung, ob der Abruf erfolgreich war",
    "dataprotectionP1Str3Con5": "Beschreibung des Typs des verwendeten Webbrowsers",
    "dataprotectionP1Str3Con6": "verwendetes Betriebssystem",
    "dataprotectionP1Str3Con7": "die zuvor besuchte Seite",
    "dataprotectionP1Str3Con8": "Provider",
    "dataprotectionP1Str3Con9": "Ihre IP-Adresse",
    "dataprotectionP2Header": "2. Personenbezogene Daten",
    "dataprotectionP2Str1": "Personenbezogene Daten werden von uns nur dann verarbeitet, wenn uns dies gesetzlich gestattet ist, oder Sie uns Ihre Einwilligung erteilt haben.",
    "dataprotectionP2Str2": "Wenn Sie mit uns in Kontakt treten, speichern wir Ihre Daten auf Grundlage von Art. 6 Abs. 1 Buchst. b) DSGVO zum Zwecke der Bearbeitung Ihrer Anfrage, sowie für den Fall, dass eine weitere Korrespondenz stattfinden sollte.",
    "dataprotectionP3Header": "3. Verwendung von Google Webfonts und Google Maps",
    "dataprotectionP3Str1": "Auf unserer Webseite werden externe Schriften, sog. Google Webfonts, verwendet. Zudem benutzen wir Google Maps, um geographische Orte besser darzustellen.",
    "dataprotectionP3Str2": "Hierbei greifen wir auf Dienste der Google Inc. 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA zurück. Dazu lädt beim Aufrufen unserer Seite Ihr Browser die benötigten Informationen vom Google-Server in den USA in ihren Browsercache. Dies ist notwendig damit auch Ihr Browser eine optisch verbesserte Darstellung unserer Texte anzeigen kann, bzw. die Karte auf unserer Webseite dargestellt wird. Hierdurch wird an den Google-Server übermittelt, welche unserer Internetseiten Sie besucht haben. Auch wird Ihre IP-Adresse von Google gespeichert. Die Verarbeitung unterliegt dem Datenschutzabkommen Privacy-Shield-Abkommen. Weitergehende Informationen zu Google Webfonts finden sie unter https://developers.google.com/fonts/faq?hl=de-DE&csw=1 Weitergehende Informationen zu Google Maps finden sie unter https://www.google.com/intl/de_de/help/terms_maps.html Allgemeine Informationen zum Thema Datenschutz bei Google finden sie unter https://www.google.com/policies/privacy/ ",
    "dataprotectionP3Str3": "Wir stützen den Einsatz des vorgenannten Tools auf Art. 6 Abs. 1 Buchst. f) DSGVO: die Datenverarbeitung erfolgt zur Verbesserung der Nutzerfreundlichkeit auf unserer Webseite und liegt daher in unserem berechtigten Interesse.",
    "dataprotectionP4Header": "4. Dauer der Speicherung",
    "dataprotectionP4Str": "Nutzungsdaten werden maximal 14 Tage gespeichert. Wenn Sie mit uns in Kontakt treten, speichern wir ihre Kontaktdaten bis auf weiteres, für den Fall einer weiteren Korrespondenz, und bis auf Widerruf.",
    "dataprotectionP5Header": "5. Betroffenenrechte",
    "dataprotectionP5Str1Header": "a. Auskunftsrecht",
    "dataprotectionP5Str1Con": "Sie haben das Recht, von uns eine Bestätigung darüber zu verlangen, ob Sie betreffende personenbezogene Daten verarbeitet werden. Senden Sie hierfür bitte einfach eine E-Mail an kontakt@mvgingen.de.",
    "dataprotectionP5Str2Header": "b. Berichtigung/Löschung/Einschränkung der Verarbeitung",
    "dataprotectionP5Str2Con1": "Des Weiteren haben Sie das Recht, von uns zu verlangen, dass",
    "dataprotectionP5Str2Con2": "Sie betreffende unrichtige personenbezogene Daten unverzüglich berichtigt werden (Recht auf Berichtigung)",
    "dataprotectionP5Str2Con3": "Sie betreffende personenbezogene Daten unverzüglich gelöscht werden (Recht auf Löschung) und",
    "dataprotectionP5Str2Con4": "die Verarbeitung eingeschränkt wird (Recht auf Einschränken der Verarbeitung).",
    "dataprotectionP5Str2Con5": "Senden Sie hierfür bitte einfach eine E-Mail an kontakt@mvgingen.de.",
    "dataprotectionP5Str3Header": "c. Recht auf Datenübertragbarkeit",
    "dataprotectionP5Str3Con": "Sie haben das Recht, Sie betreffende personenbezogene Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten und diese Daten einem anderen Verantwortlichen zu übermitteln. Senden Sie hierfür bitte einfach eine E-Mail an kontakt@mvgingen.de.",
    "dataprotectionP5Str4Header": "d. Widerrufsrecht",
    "dataprotectionP5Str4Con": "Sie haben das Recht, Ihre Einwilligung jederzeit zu widerrufen. Durch den Widerruf der Einwilligung wird die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht berührt. Senden Sie hierfür bitte einfach eine E-Mail an kontakt@mvgingen.de.",
    "dataprotectionP5Str5Header": "e. Widerspruchsrecht",
    "dataprotectionP5Str5Con": "Ist die Verarbeitung Sie betreffender personenbezogener Daten für die Wahrnehmung einer Aufgabe, die im öffentlichen Interesse liegt (Art. 6 Abs. 1 Buchst. e) DSGVO) oder zur Wahrung unserer berechtigten Interessen (Art. 6 Abs. 1 Buchst. f) DSGVO) erforderlich, steht Ihnen ein Widerspruchsrecht zu. Senden Sie hierfür bitte einfach eine E-Mail an kontakt@mvgingen.de.",
    "dataprotectionP5Str6Header": "f. Beschwerderecht",
    "dataprotectionP5Str6Con": "Sind Sie der Ansicht, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten gegen die DSGVO verstößt, haben Sie unbeschadet anderweitiger Rechtsbehelfe das Recht auf Beschwerde bei einer Aufsichtsbehörde.",
    "dataprotectionP6Header": "6. Einsatz von Cookies",
    "dataprotectionP6Str1": "Beim Besuch dieser Webseite können Cookies auf ihr Gerät gespeichert werden. Dabei handelt es sich um kleine Textdateien, die dazu dienen, dieses Internetangebot benutzerfreundlicher zu gestalten. Durch Browsereinstellungen kann das setzen von Cookies eingeschränkt oder verhindert werden. Dadurch stehen möglicherweise nicht mehr alle Funktionen dieser Webseite zur Verfügung.",
    //Kontakt
    "kontaktHeadline": "Kontakt",
    "kontaktP1": "Sie haben Fragen an uns? Nehmen Sie gerne Kontakt zu uns auf!",
    "kontaktVorstandDes": "Vorsitzender",
    "kontaktJugendDes": "Jugendleiterin",
    "kontaktWebsiteDes": "Webseite",
    "kontaktSponsoringDes": "Sponsoring",
    "kontaktVorstandName": "Markus Windstoßer",
    "kontaktJugendName": "Eva Riesner",
    "kontaktWebsiteName": "Marcel Zeller",
    "kontaktSponsoringName": "Wolf-Dieter Fauth",
    "kontaktVorstandMail": "vorstand@mvgingen.de",
    "kontaktJugendMail": "jugendleiter@mvgingen.de",
    "kontaktWebsiteMail": "webseite@mvgingen.de",
    "kontaktSponsoringMail": "sponsoring@mvgingen.de",
    "kontaktStellvVorstandDes": "Musikervorstand",
    "kontaktStellvVorstandName": "Jonas Steegmaier",
    "kontaktStellvVorstandMail": "stv.vorstand@mvgingen.de",
    "kontaktP2": "Sie finden unser Musikerheim in der Grabenstraße 95 in Gingen. Dies ist am Ortsende Richtung Süßen.",
    //Main side
    "mainHeadline": "Blasmusik in der Perle des Filstals",
    "mainP1": "Wir sind ein Blasorchester mit etwa 55 Musikern und einer Jugendkapelle.",
    "mainP2": "Unser Repertoire reicht von modern und klassisch konzertant über traditionelle Marsch- und Polkamusik bis zu moderner Unterhaltungsliteratur.",
    "newsCard1Name": "Benefizkonzert",
    "newsCard1Des": "Heeresmusikkorps Ulm",
    "newsCard2Name": "Unsere Reise",
    "newsCard2Des": "Video",
    "newsCard3Name": "Jahresfeier",
    "newsCard3Des": "Einladung",
    "newsCard4Name": "Schnittlingen",
    "newsCard4Des": "Rückblick",
    "newsCard5Name": "Maihock",
    "newsCard5Des": "Rückblick",
    "newsCard6Name": "Dirigentensuche",
    "newsCard6Des": "Stellenbeschreibung",
    "mainPSponsors": "Wir werden untersützt von",
    //Termine
    "termineHeadline": "Termine",
    "termineImportHeadline": "Importieren in lokalen Kalender",
    "termineImportComplete": " Alle Termine 2022",
    "termineImportAktive": " Termine der Aktiven Kapelle 2022",
    "termineImportJugend": " Termine der Jugend 2022",
    "newsDLehrgaengeHeadline": "D-Lehrgänge 2021",
    "newsDLehrgaengeSideInfo": "In den Herbstferien führte der Blasmusikkreisverband Göppingen die D1- und D2-Lehrgänge für die Jugendleistungsabzeichen in Bronze und Silber durch.",
    "newsDLehrgaengeP1": "Nach einem Jahr corona-bedingter Pause haben in den Herbstferien 10  unserer Jungmusiker an den D-Lehrgängen des Kreisverbandes teilgenommen. Die erfolgreiche Teilnahme aller Jugendlichen zeigt deren sehr gute Ausbildung und die Freude am Musizieren. Wir freuen uns ganz besonders die Jugendlichen nach bestandener D2-Prüfung in die Aktive Kapelle übernehmen zu dürfen.",
    "newsDLehrgaengeP2": "Herzlichen Glückwunsch zu den bestandenen Prüfungen. Macht weiter so.",
    "newsDLehrgaengeP3": "<i>Jonas Steegmaier (Jugendleiter)</i>",
    "newsAusbildungsjahrHeadline": "Wir suchen Dich! Erlerne mit uns ein Instrument!",
    "newsAusbildungsjahrSideInfo": "Liebe musikinteressierte Kinder, Jugendliche und Erwachsene,",
    "newsAusbildungsjahrP1": "wir starten auch dieses Jahr wieder in ein neues Ausbildungsjahr. Ihr habt die Möglichkeit eines von vielen Musikinstrumenten zu erlernen. Egal ob Klarinette, Trompete, Posaune oder Schlagzeug, bei uns ist für jeden das passende Instrument dabei. Nach einem Jahr dürft ihr schon in unserer Jugendgruppe mitspielen und ihr seid von Beginn an bei unseren Freizeitaktivitäten dabei. Bei Interesse beraten wir euch gerne.",
    "newsAusbildungsjahrP2": "Meldet euch einfach ganz unverbindlich bei unserem Jugendleiter Jonas Steegmaier (jugendleiter@mvgingen.de oder 0163/1600496).",
    "newsAusbildungsjahrP3": "unser Ausbildungskonzept",
    "newsSpielenachmittagHeadline": "Spielenachmittag der Jugend",
    "newsSpielenachmittagSideInfo": "Unsere Jugend hat sich zum Saisonabschluss im Musikerheim getroffen und ganz ohne Instrumente viel Spaß bei verschiedenen Spielen gehabt.",
    "newsSpielenachmittagP1": "\"Am Samstagnachmittag, den 13. Juli, hat sich die Jugend des Musikvereins zu einem gemeinsamen Spielenachmittag getroffen. Ab 12.30 Uhr trafen nach und nach alle Kinder und Jugendliche mit Taschen voller Spielen ein.",
    "newsSpielenachmittagP2": "Voller Begeisterung wurden die mitgebrachten Spiele gespielt, wie zum Beispiel Scotland Yard, Kristallica, aber auch der Klassiker Monopoly.",
    "newsSpielenachmittagP3": "Nach zwei Stunden gab es eine Eispause. Wir durften uns unseren Eisbecher selbst zusammenstellen. Es gab viele verschiedene Eissorten, schokoladige Dekoration und natürlich ganz viel Obst. Nachdem wir alle unseren Eisbecher gegessen hatten (mit Nachschlag) spielten wir unsere Spiele weiter. Um 15.30 Uhr war der schöne Spiele-Nachmittag auch schon zu Ende.",
    "newsSpielenachmittagP4": "Vielen Dank an unseren Jugendleiter Jonas, der den tollen Nachmittag für uns organisiert hat.\"",
    "newsSpielenachmittagP5": "<i>Maya</i>",
    "newsEndeFerienHeadline": "Zweite Jahreshälfte",
    "newsEndeFerienSideInfo": "Der August und die Sommerferien neigen sich dem Ende zu. Daher beginnen auch bei uns wieder die wöchentlichen Proben.",
    "newsEndeFerienP1": "Die Aktive Kapelle probt bereits am 30. August wieder. Die Jugend startet in der ersten Schulwoche: Für die Jugendkapelle bedeutet das, die erste Probe findet am 13. September statt. Die Jugendgruppe startet mit den Vorbereitungen der Jahresfeier am 17. September.",
    "newsEndeFerienP2": "Am 22. September steht dann auch unser Herbstfest rund um das Musikerheim an. Bereits jetzt eine Herzliche Einladung dazu!",
    "newsJugendkonzertHeadline": "Gemeinsames Jugendkonzert mit Süßen und Salach",
    "newsJugendkonzertSideInfo": "Unsere Jugendkapelle hat am 12. Juli ein gemeinsames Konzert mit der Jugend des Musikverein Süßen und des Musikverein Salach gespielt. Im Rahmen des Stadtfestkonzerts hat die Musikschule Süßen mit den Dirigenten der drei Vereine das Jugendprojektorchester erstmalig zusammengeführt.",
    "newsJugendkonzertP1": "Jeder der drei Dirigenten hat Musikstücke eingebracht, die dann vom gemeinsamen Projektorchester aller drei Vereine in mehreren gemeinsamen Proben und Registerproben erarbeitet wurden. Neben Apollo 11 und einem Arragement von Fluch der Karibik wurden auch die beiden Pop-Titel All about that bass und Shout up and dance gespielt. Außerdem stand das Stück Ghost, Goblins and Witches auf dem Programm, das unseren Jugendlichen bereits vom vergangenen Wertungsspiel bekannt war. Als Herausforderung stellten sich Unterschiede im Leistungsstand heraus, bedingt durch unterschiedlich langes Musizieren der Jugendlichen. Trotzdem war das Jugendprojektkonzert für alle beteiligten Jugendlichen eine tolle Erfahrung, die sicherlich auch den Zusammenhalt zwischen den Vereinen positiv beeinflussen kann.",
    "newsHerbstfestHeadline": "Herbstfest",
    "newsHerbstfestSideInfo": "Am Sonntag, 24. September fand unser Herbstfest rund um unser Musikerheim statt.",
    "newsHerbstfestP1": "In unseren Garten rund ums Musikerheim hat ab 11 Uhr Just Music das Herbstfest eröffnet. Mit einer bunten Mischung aus moderner, zeitlosen und traditioneller Blasmusik sorgten die Musiker für beste Unterhaltung. Im Anschluss hat unsere Jugendgruppe unter der Leitung von Hannah Adametz und unsere Jugendkapelle unter der Leitung von Gunnar Merkert übernommen. Am Nachmittag war dann der Musikverein Deggingen zu Gast.",
    "newsHerbstfestP2": "Die zahlreichen Gäste konnten sich neben Roter Wurst, Currywurst und Pommes auch Filstal-Linsen mit Spätzle und Saiten schmecken lassen, außerdem auch Kaffee und Kuchen.",
    "newsHerbstfestP3": "Für unsere kleinen Gäste haben wir uns ein lustiges Kinder-Spieleprogramm überlegt. Wir freuen uns auf viele Gäste!",
    "newsJahresfeierHeadline": "Jahresfeier",
    "newsJahresfeierSideInfo": "Zum Ende des Jahres findet unsere traditionalle Jahresfeier statt. Erstmalig findet diese in der neu gebauten BOSIG-Hohensteinhalle statt.",
    "newsJahresfeierP1": "Am 7. Dezember laden wir ab 19:00 Uhr in die BOSIG-Hohensteinhalle zur Jahresfeier ein. Es werden neben unserer Rhythmusgruppe unter der Leitung von Hannah Adametz und der Jugendkapelle unter der Leitung von Gunnar Merkert natürlich auch die Aktive Kapelle, ebenfalls unter der Leitung von Gunnar Merkert, zu hören sein. Eintritt frei.",
    "newsJahresfeierP2": "Programm:",
    "newsJahresfeierP3": "<b>Jugendgruppe (Leitung Marcel Zeller)</b><br>Do-Re-Mi - From \"The Sound of Music\" - <i>Richard Rodgers, arr. Paul Lavender</i><br>Ain't gonna rain no more - <i>arr. Andrew Balent</i>",
    "newsJahresfeierP4": "<b>Jugendkapelle (Leitung Gunnar Merkert)</b><br>Bacarole & Can Can - <i>Jaques Offenbach, arr. Alfred Bösendorfer</i><br>The best of James Bond - <i>arr. Paul Murtha</i><br>Chorus und Marsch - <i>G.F. Händel, arr. Philip Sparke</i>",
    "newsJahresfeierP5": "<b>Aktive Kapelle (Leitung Gunnar Merkert)</b><br>Vanazia - <i>Gaetano Fabiani, arr. Siegfried Rundel</i><br>Slovenia - <i>Alfred Bösendorfer</i><br>Kaiserin Sissi - <i>Timo Dellweg</i><br>Böhmische Liebe - <i>Mathias Rauch</i><br>80er Kult(Tour) - <i>arr. Thiemo Kraas</i><br>Highlights aus \"Frozen\" - <i>arr. Sean O'Loughlin</i>",
    "newsSchnapperfasnetHeadline": "Schnapperfasnet",
    "newsSchnapperfasnetSideInfo": "Was für eine tolle Party! Mit bestem Party-Mix und eingängigen Dancefloor-Hits durch DJ Suni und einem Live-Auftritt von Chriss Reiser fand am 8.2.2020 die Schnapperfasnet 2020 statt.",
    "newsSchnapperfasnetP1": "Die feierfreudigen und fantasievoll verkleideten Partygäste strömten schon von Beginn an in die zur Partymeile dekorierten Hohensteinhalle und so wurde es eine gelungene Veranstaltung. Die Tanzfläche wurde ausgiebig genutzt und erstklassige Lichteffekte sorgten für die passende Atmosphäre.",
    "newsSchnapperfasnetP2": "Wir danken allen Helfern und Gästen für einen wundervollen Abend, an den wir uns alle gerne erinnern. Dies war möglich durch die freundliche Unterstützung von EnBW, Moloko, Finanz + Wirtschaftsberatung Locher, Pfadfindergruppe Donzdorf, Garten- und Landschaftsbau Steck sowie Staufen Baubedarf.",
    "newsHauptversammlungHeadline": "Hauptversammlung",
    "newsHauptversammlungSideInfo": "Einladung zur Hauptversammlung 2024",
    "newsHauptversammlungP1": "Die diesjährige Jahreshauptversammlung findet statt am: <br>Samstag, den 09.03.2024 um 19.30 Uhr im <b>Musikerheim, Grabenstraße 95, 73333 Gingen</b>.",
    "newsHauptversammlungP2": "Es gilt folgende Tagesordnung:",
    "newsHauptversammlungO1": "Begrüßung und Eröffnung der Hauptversammlung",
    "newsHauptversammlungO20": "Berichte",
    "newsHauptversammlungO21": "Bericht des Schriftführers",
    "newsHauptversammlungO22": "Bericht des 1. Vorsitzenden",
    "newsHauptversammlungO23": "Bericht des Dirigenten",
    "newsHauptversammlungO24": "Bericht des Jugendleiters",
    "newsHauptversammlungO25": "Kassenbericht",
    "newsHauptversammlungO26": "Bericht des Wirtschaftsorganisators",
    "newsHauptversammlungO27": "Bericht der Kassenprüfer",
    "newsHauptversammlungO30": "Entlastungen",
    "newsHauptversammlungO31": "Entlastung des Kassiers durch die Kassenprüfer",
    "newsHauptversammlungO32": "Entlastung der Vorstandschaft",
    "newsHauptversammlungO40": "Wahlen",
    "newsHauptversammlungO41": "Vorsitzender",
    "newsHauptversammlungO42": "Schriftführer (1 Jahr)",
    "newsHauptversammlungO43": "2 Beisitzer",
    "newsHauptversammlungO44": "1 Kassenprüfer",
    "newsHauptversammlungO5": "Anträge",
    "newsHauptversammlungO6": "Verschiedenes",
    "newsHauptversammlungP3": "Anträge sind spätestens 2 Wochen vor der Hauptversammlung beim 1. Vorsitzenden Dr. Markus Windstoßer, Im Marrbacher Öschle 20, 73333 Gingen einzureichen.<br>Alle Ehrenmitglieder, Mitglieder, Musikerinnen und Musiker sind zu der Hauptversammlung herzlich eingeladen.",
    "newsHauptversammlungP4": "Gingen, den 19.01.2024<br>gez. Dr. Markus Windstoßer<br>1. Vorsitzender",
    "newsHauptversammlungA01": "Bericht der 55. Hauptversammlung vom 14.03.2020",
    "newsHauptversammlungA02": "Der 1. Vorsitzende Mario Deger konnte pünktlich um 19.30 Uhr im Vereinsheim des Musikvereins die anwesenden Ehrenmitglieder, Mitglieder, Musikerinnen und Musiker zur diesjährigen Hauptversammlung begrüßen.<br>Im Anschluss verlas der 1. Vorsitzende die Tagesordnungspunkte.<br>In einer Schweigeminute gedachte man den im vergangenen Jahr verstorbenen Vereinsmitglieder.<br>Schriftführer Wolf-Dieter Fauth verlas das Protokoll der letztjährigen 54. Hauptversammlung vom 09.03.2019.",
    "newsHauptversammlungA03": "Der darauffolgende Bericht des 1. Vorsitzenden Mario Deger begann mit einem Rückblick auf das vergangene Jahr 2019 in wirtschaftlicher und musikalischer Hinsicht.<br>Aufgrund besten Wetters und erfreulichen Besucherzahlen konnten am 09.06.2019 der Musikerhock und am 22.09.2019 das Herbstfest in wirtschaftlicher Hinsicht positiv abgehalten werden. Ebenso wurde das Schnapperfest am 29.06.2019 mit Erfolg mitgestaltet. Am 05.07.2019 wurde im Garten des Musikerheim erstmals eine Sommerserenade abgehalten, was von den zahlreichen Besuchern begeistert aufgenommen wurde.<br>In musikalischer Hinsicht konnte sich die Kapelle bei zahlreichen Auftritten wie beim Knoblauchfest in Nenningen am 30.05.2019, beim Stadtfest in Eislingen am 06.07.2019, beim Stadtfest in Süßen am 14.07.2019 und beim Gartenfest in Böhmenkirch am 09.08.2019 mit einem guten Eindruck der Öffentlichkeit präsentieren.<br>Als Vorschau im musikalischen Bereich für das Jahr 2020 stellte Mario Deger den Musikerhock am 14.06.2020, die Sommerserenade am 03.07.2020, das Herbstfest am 27.09.2020 sowie die Jubiläumsveranstaltungen des Turnerbundes vor. Ebenso wirkt die Kapelle am 18.06.2020 beim diesjährigen NWZ-Blasmusikfestival in Göppingen mit.",
    "newsHauptversammlungA04": "Das geplante Frühjahrskonzert in der Hohensteinhalle am 04.04.2020 und das Konzert in Wangen am 26.04.2020 werden aufgrund der aktuellen Lage der Corona-Krise abgesagt. Ebenso wird der gesamte Probenbetrieb der aktiven Kapelle, Jugendkapelle und Jugendgruppe mit sofortiger Wirkung bis vorerst Ende der Osterferien eingestellt. Das Vereinsheim des Musikvereins bleibt ebenso bis auf weiteres geschlossen.<br>Der 1. Vorsitzende teilte der Versammlung mit, dass aktuell ein Sponsoringkonzept ausgearbeitet wird und bereits mit EnBW und Finanz- und Wirtschaftsberatung Thomas Locher zwei namhafte Sponsoren gewonnen werden konnten. Als Sponsoringbeauftragter des Musikvereins wurde Wolf-Dieter Fauth bekannt gegeben.<br>Als Webmaster des Musikvereins wurde Marcel Zeller bekannt gegeben. Rico Scheiding wurde für seine in der Vergangenheit getätigte Arbeit gedankt.<br>Mario Deger teilte noch mit, dass im Oktober ein Kameradschaftsabend in einer Besen-wirtschaft stattfinden wird, der momentan von Wolf-Dieter Fauth organisiert wird.<br>Die komplett neu organisierte Faschingsveranstaltung am 08.02.2020 in der Hohensteinhalle war im Großen und Ganzen ein Erfolg. Die „Schnapperfasnet“ findet künftig im Partystil mit DJ Suni und Chriss Reiser statt. Dieses erfolgreiche Konzept wird man künftig weiter ausbauen.<br>Vereins- und Musikerausschuss arbeiten derzeit zusammen an einem Zukunftskonzept „Musikverein 2025“. Hier soll der Musikverein zukunftsorientiert für die nächsten 5 Jahre unter anderem in den Schwerpunktthemen „Aufgaben- und Arbeitsverteilung“, „Vereinsstruktur“, „musikalische Ausrichtung“ und „Jugendarbeit“ aufgestellt werden.",
    "newsHauptversammlungA05": "Anschließend folgte der Bericht des Dirigenten Gunnar Merkert, der den momentanen Leistungsstand der Jugendkapelle lobte. Beim Wertungsspiel im Frühjahr in Altheim/Staig konnte die Jugendkapelle mit 88,5 Punkten und dem Ergebnis „mit sehr gutem Erfolg“ die Wertungsrichter überzeugen. Der Dirigent berichtet, dass sich die aktive Kapelle momentan inmitten der Vorbereitung des Frühjahrskonzertes befindet, welches aus bekannten Gründen abgesagt wurde. Die bisher geprobten Musikstücke sollen im Laufe des Jahres bei verschiedenen Auftritten aufgeführt werden.<br>Abschließend bedankte sich der Dirigent bei allen, die ihn das ganze Jahr über unterstützt haben und wünscht allen, dass sie die momentane Corona-Krise gesund überstehen werden.",
    "newsHauptversammlungA06": "Der Bericht des Jugendleiters, Jonas Steegmaier, der entschuldigt fehlte, wurde vom 1. Vorsitzenden Mario Deger vorgetragen.<br>Jugendleiter Jonas Steegmaier hob die gute Ausbildung der Jungmusiker in seinem Bericht besonders hervor. Die Jugendkapelle besteht derzeit aus 13 Jugendlichen und wird von Gunnar Merkert geleitet. Die Jugendgruppe umfasst zurzeit 9 Jugendliche unter der Leitung von Marcel Zeller. Die Jugendkapelle und die Jugendgruppe spielten beim Maibaum aufstellen am 01.05.2019, bei der Sommerserenade am 05.07.2019 sowie bei der Jahresfeier am 07.12.2019. Außerdem hatte die Jugendkapelle weitere Auftritte beim Frühjahrskonzert am 23.03.2019, beim Schnapperfest am 29.06.2019, beim Stadtfestkonzert in Süßen sowie beim Schulfest in der Hohensteinschule am 19.07.2019. Der musikalisch wichtigste Auftritt der Jugendkapelle wird jedoch das Wertungsspiel am 24.03.2019 in Altheim/Staig gewesen sein. Neben den genannten Auftritten fanden auch zahlreiche außermusikalische Aktivitäten statt.<br>Zwei Jugendliche haben an einem D1-Lehrgang erfolgreich teilgenommen. Dies waren Annika Mauz und Julia Wörz. Chiara Blessing, Lara Fetzer und Niklas Stadelmayer legten die D2-Prüfung erfolgreich ab und wurden in die aktive Kapelle aufgenommen. In Sachen Jugendwerbung im vergangenen Jahr lässt der Jugendleiter verkünden, dass man nach intensiven Werbeveranstaltungen 7 Neuzugänge gewinnen konnte.<br>Nach Bekanntgabe der geplanten Auftritte für das Jahr 2020 sowie den anstehenden außermusikalischen Aktivitäten bedankte sich Jonas Steegmaier bei der Vorstandschaft für die gute Zusammenarbeit, sowie bei all denen, die ihn das ganze Jahr über unterstützt haben.",
    "newsHauptversammlungA07": "Nach dem Bericht der Ideellen Kasse sowie der Wirtschaftskasse durch Harald Riesner waren die Zuhörer auf dem neuesten Stand, was die finanziellen Angelegenheiten des Musikvereins anbelangt. Harald Riesner berichtete der Hauptversammlung, dass die Vorstandschaft beschlossen hat, den jährlichen Mitgliedsbeitrag entsprechend der Inflationsrate von EUR 30,00 auf EUR 30,50 für Einzelmitgliedschaft und die Familienmitgliedschaft von EUR 75,00 auf EUR 76,25 zu erhöhen.",
    "newsHauptversammlungA08": "Wirtschaftsorganisator Armin Vogel bedankte sich in seinem Bericht bei allen seinen Helfern für die große Hilfsbereitschaft bei all den durchgeführten Veranstaltungen im abgelaufenen Jahr und hofft auf weiterhin gute Zusammenarbeit mit seinem bewährten Team.",
    "newsHauptversammlungA09": "Für die entschuldigten Kassenprüfer Florian Stadelmayer und Martin Mann trug Elke Pflüger deren Bericht der Hauptversammlung vor. Die Kassenprüfer konnten ordnungsgemäß geführte Kassen bestätigen, was zu einer einstimmigen Entlastung des Kassiers führte. Anschließend bat Elke Pflüger die Teilnehmer der Hauptversammlung um Entlastung der gesamten Vorstandschaft, die einstimmig erfolgte.",
    "newsHauptversammlungA10": "Unter dem Punkt „Wahlen“ wurde Mario Deger einstimmig als 1. Vorsitzender für weitere zwei Jahre gewählt.<br>Schriftführer Wolf-Dieter Fauth, die passiven Beisitzer Claudia Pomeissl und Ulrike Putz sowie Kassenprüfer Martin Mann wurden ebenfalls einstimmig für weitere zwei Jahre gewählt.<br>Unter dem Punkt „Anträge“ gingen keine Meldungen ein.<br>Unter dem Punkt „Verschiedenes“ bedankte sich Mario Deger für die Spendenbereitschaft der Mitglieder, der Gingener Bürger sowie unserer Sponsoren, für die Mithilfe und Unterstützung des Rathauses und Bauhofes, sowie dem Fleiß der aktiven Musikerinnen und Musiker, ebenso bei den Helfern im Wirtschaftsbereich.",
    "newsHauptversammlungA11": "Abschließend wünschte der 1. Vorsitzende allen eine gute Zeit und gutes, gesundes Überstehen der Corona-Pandemie.<br>Der 1. Vorsitzende Mario Deger konnte um 21.30 Uhr die Hauptversammlung schließen.",
    "newsCoronaHeadline": "Wiederaufnahme des Probenbetriebs",
    "newsCoronaSideInfo": "Speziell für Musikvereine wurden vom Blasmusikverband Baden-Württemberg (BVBW) jüngst nochmals Anpassungen veröffentlicht, die Veranstaltungen und Musikproben, unter Einhaltung von umfangreichen Hygiene- und Sicherheitsregeln, auch in größeren Personenzahlen ermöglicht.",
    "newsCoronaP1": "Für uns sind diese Änderungen nun endgültig der Startschuss, um wieder mit unserer musikalischen Arbeit zu beginnen. Seit Freitag, den 11.6.21 haben unsere musikalischen Gruppen den Probenbetrieb wieder aufgenommen. Für die Probendurchführung sind zahlreiche Regeln einzuhalten, viele davon begegnen uns jedoch schon seit Wochen und sind bereits fester Bestandteil unseres Alltags.",
    "newsCoronaP2": "Gemeinsam wurde in den letzten Tagen und Wochen viel Zeit und Engagement investiert, um Hygiene- und Sicherheitspläne zu erstellen, Abläufe zu beschreiben, die Musiker zu informieren, Abstimmungen mit dem Blasmusikverband durchzuführen sowie notwendige Utensilien besorgen, damit dem Probenstart nichts mehr im Weg steht. So gilt nun ein überarbeitetes Hygienekonzept. Neu sind die 3G (genesen, geimpft oder getestet) für die Teilnahme an den Proben.",
    "newsCoronaP3": "Die Jugendgruppe und die Jugendkapelle proben im Musikerheim.",
    "newsCoronaP4": "Die aktive Kapelle muss aufgrund der erforderlichen Raumgröße bis auf weiteres in den Mehrzweckraum in der Hohensteinschule ausweichen, damit die Abstände beim Musizieren eingehalten werden können. Herzlichen Dank an dieser Stelle an die Gemeindeverwaltung, die uns die Nutzung dieses Raums ermöglicht.",
    "newsChronikHeadline": "Heute vor 10 Jahren",
    "newsChronikSideInfo": "In einer Zeit der Einschränkungen wollen wir den Rückblick auf die vergangenen Jahre des Musikvereins Gingen/Fils fortsetzen:",
    "newsChronikP1H": "Vor 75 Jahren",
    "newsChronikP2H": "Vor 60 Jahren",
    "newsChronikP3H": "Vor 50 Jahren",
    "newsChronikP4H": "Vor 40 Jahren",
    "newsChronikP5H": "Vor 30 Jahren",
    "newsChronikP6H": "Vor 20 Jahren",
    "newsChronikP1": "So endete am 8. Mai 1945 der zweite Weltkrieg und forderte auch aus den Reihen des Musikvereins zahlreiche Opfer. Bereits wenige Monate später nahmen Albert Steck und 10 weitere Musiker ihre Instrumente wieder zur Hand und probten regelmäßig. Mit den abnehmenden Folgen der schweren Nachkriegsjahre gewann mit dem kulturellen Leben auch die Volksmusik wieder an Bedeutung und die Kapelle des Musikvereins nahm bereits 1949 beim Musikfest in Wernau bei einem Wertungsspiel teil und erzielte den 1. Rang.",
    "newsChronikP2": "In den Jahren 1951 – 1960 nahm das kulturelle Leben wieder Schwung auf. In diesen Jahren wurde auch der Leitspruch „Oh Musica, so hold und rein, dir sei stets Hort am Hohenstein“ geprägt. Beim 1. Gingener Heimattag trat die Kapelle erstmals in zwei Gruppen auf. Es folgten zahlreiche Auftritte und Teilnahmen bei Musikfesten in Gingen und der näheren Umgebung sowie ein Besuch bei der Gastkapelle in der Schweiz. Die erstmalige Anschaffung einer einheitlichen Uniform wurde durch die Unterstützung der Gemeinde sowie vieler Geschäftstreibender aus Gingen zu einem Meilenstein in der Vereinsgeschichte.",
    "newsChronikP3": "Es folgten ereignisreiche Jahre des Aufschwungs; dies ermöglichte vielfältige Auftritte der Musikkapelle bei Musik- und Gartenfesten sowie zahlreichen Wertungsspielen. Der Gegenbesuch der Musikfreunde aus der Schweiz wurde mit einem zweitägigen Fest gebührend gefeiert. Den Höhepunkt dieses Jahrzehntes bildete das 50. Gründungsfest sowie die Umwandlung der Musikkapelle in „Musikverein Gingen/Fils 1912 e.V.“ Mit großem Einsatz der Vereinsmitglieder wurde das Vereinsheim erbaut, was dem Verein in finanzieller Hinsicht viel abverlangte.",
    "newsChronikP4": "Im Jahr 1982 konnte das 70-jährige Bestehen des Musikvereins mit einem 4-tägigen Fest gebührend gefeiert werden. Schon im darauffolgenden Jahr stemmte der Verein ein 4-tägiges Zeltfest, bei dem das Theaterstück „Der Watzmann“ aufgeführt wurde. Durch eine Vielzahl weiterer Auftritte und einem Zuschuss der Gemeinde war die Ausstattung der Kapelle mit einer neuen Uniform möglich. Den Höhepunkt dieses Jahrzehntes bildete die Anschaffung der neuen Vereinsfahne, die durch Spendengelder möglich wurde und im Rahmen der Jubiläumsfeierlichkeiten anlässlich des 75-jährigen Bestehens vorgestellt wurde.",
    "newsChronikP5": "Die Jahre 1991 – 2000 waren geprägt von einer Vielzahl musikalischer Aktivitäten sowie von hohem Eifer der Wirtschaftergruppe. Der Auftritt der Band „English-Connection“ im Musikerheim wurde für alle Besucher zu einem unvergessenen Erlebnis. Die 4-tägigen Feierlichkeiten zum 80-jährigen Bestehen des Musikverein Gingen/Fils beinhaltete eine Übertragung im Radio. Besonderes Augenmerk lag auf der Jugend- und Nachwuchsarbeit, die durch zahlreiche außermusikalische Aktivitäten unterstützt wurde. Herausragende Erinnerungen haben die Teilnehmer an die Reise ins Berchtesgadener Land (Tour 96) sowie die Reise der Jugendkapelle nach Spanien (Tour 2000).",
    "newsChronikP6": "In gewohnter Weise absolvierte der Musikverein Gingen/Fils eine Vielzahl von Auftritten. Diese umfassten neben dem traditionellen Musikerhock, der bis 2006 2-tägig durchgeführt wurde, zahlreiche Darbietungen bei Stadtfesten und Promenadenkonzerten in der näheren Umgebung sowie Veranstaltungen der Gemeinde und Kirchengemeinden. Ebenso nahmen die aktive Kapelle sowie die Jugendkapelle des Öfteren an Wertungsspielen teil, um den Leistungsstand unter Beweis zu stellen.<br>Auch stand bereits die erste große Renovierung des Musikerheims an, die mit viel Engagement der Vereinsmitglieder durchgeführt wurde.<br>Mit großer Begeisterung aller Teilnehmer fand nach intensiver Probenarbeit die Aufführung des Märchen-Musicals „Freude“ als Kooperationsprojekt des Musikvereins Gingen/Fils, der Hohensteinschule sowie des Förderkreises statt.",
    "newsChronikP7": "Dieses Jahrzehnt war geprägt von der Planung und Durchführung der Feierlichkeiten anlässlich des 100-jährigen Bestehens des Musikvereins Gingen/Fils.<br>Das Festjahr beinhaltete neben den jährlich wiederkehrenden Terminen wie Kinderfest, Musikerhock, Kur- und Platzkonzerte, Festumzüge, Garten- und Straßenfeste und Mitgestaltung von Gottesdiensten zahlreiche weitere Veranstaltungen. Die erstellte Festschrift zeigte die Vielzahl der Festtermine an, beginnend mit einem feierlichen Festakt, bei dem als seltene Auszeichnung dem Musikverein Gingen/Fils die „Pro-Musica-Plakette“ verliehen wurde. Herausragend war auch das 3-tägige Festwochenende mit der Darbietung des Großen Zapfenstreiches, viel musikalischer Unterhaltung sowie dem Spiel ohne Vereinsgrenzen.<br>Um den Werdegang und die Entwicklung des Musikvereins Gingen/Fils für alle Freunde des Vereins festzuhalten, wurde eine fast 400-seitige Chronik erstellt.",
    "newsChronikPE": "Dies ist ein kleiner Auszug aus der Chronik des Musikvereins, die zum 100-jährigen Jubiläum erstellt wurde. Es gibt viele weitere interessante Begebenheiten in dieser Chronik nachzulesen. In einer Zeit der Besinnung ist dies eine gute Gelegenheit, sich näher mit der Chronik zu befassen. Gerne können Sie bei unserem 1. Vorsitzenden unter 07162/30 51 82 oder 0179/2179887 oder mario.deger@web.de ein Exemplar bestellen.",
    "newsProbenGemeindeHeadline": "Freiluft-Proben",
    "newsProbenGemeindeSideInfo": "Da wir derzeit mit der Aktiven Kapelle noch nicht im Musikerheim proben dürfen, führen wir Proben im Freien an unterschiedlichen Orten in der Gemeinde durch.",
    "newsProbenGemeindeP1": "Die erste Freiluft-Probe hat auf der Wiese neben dem Waldheim oberhalb des Ortes stattgefunden. Nach über 3 Monaten haben wie erstmalig wieder gemeinsam musizieren können. Auch viele Gingener konnten uns zuhören. Weitere Proben haben am Sauerbrunnen in der Ortsmitte, auf dem Kirchplatz neben der Johanneskirche und im Vis-a-Vis an der Fils stattgefunden.",
    "newsWeihnachtenHeadline": "Frohe Weihnachten",
    "newsWeihnachtenSideInfo": "Auch dieses Jahr musste unsere Jahresfeier leider entfallen. In der letzten Probe haben wir daher gemeinsam mit den Jungmusikern der Jugendkapelle mehrere Lieder eingespielt. Als Weihnachtsgruß an alle Mitglieder, Freunde und Förderer haben wir \"Überall ist Weihnacht\" gespielt. Die Jugendkapelle hat außerdem das für die Jahresfeier geprobte Stück \"Sun Calypso\" aufgenommen und die Jugendgruppe \"Power Rock\" sowie \"O Come Little Children\".",
    "newsGingenerMarschHeadline": "Gingen, du Perle des Filstals",
    "newsGingenerMarschSideInfo": "Als Neujahrsgruß hat die Jugendkapelle und die Aktive Kapelle gemeinsam den Vereinsmarsch \"Gingen, du Perle des Filstals\" eingespielt.",
    "newsSchnittlingenHeadline": "Straßenfest Schnittlingen",
    "newsSchnittlingenSideInfo": "Am Vatertag waren wir bei unseren Musikerfreunden in Schnittlingen zu Gast",
    "newsSchnittlingenP1": "Im Festzelt in der Ortsmitte hat der MV Schnittlingen wieder zum Straßenfest am Vatertag eingeladen. Bei bestem Wetter haben wir den Nachmittag über das Festzelt unterhalten.",
    "newsSchnittlingenP2": "Insbesondere die Musiker der Kapelle vor uns, der MV Winterbach, waren in Feierlaune und haben unsere Stimmungsmusik genutzt. Im Anschluss an uns haben die Gastgeber übernommen und auch wir konnten noch gut feiern.",
    "newsMaiHeadline": "Maihock",
    "newsMaiSideInfo": "Am 1. Mai wurde in Gingen der Maibaum gestellt",
    "newsMaiP1": "Der erste Unterhaltungsauftritt der Saison fand statt im Rahmen der Maibaumstellung durch den Schwäbischen Albverein und die Feuerwehr. Sowohl unsere Jugend als auch die Aktive Kapelle haben auf dem Rathausvorplatz für gute Unterhaltung gesorgt.",
    "newsJugendMusiziertHeadline": "Erfolgreiche Jungmusiker beim Musikverein Gingen/Fils",
    "newsJugendMusiziertSideInfo": "Beim diesjährigen Regionalwettbewerb „Jugend musiziert“ haben unsere Jungmusiker Finn Streubel und Lukas Stumpp einen 1. Preis in der Kategorie „Schlagzeug“ erreicht und erhielten somit die Weiterleitung zum Landeswettbewerb.",
    "newsJugendMusiziertP1": "Das Daumendrücken hat sich gelohnt! Die beiden haben beim 58. Landeswettbewerb „Jugend musiziert“ vom 16.-18.07.2021 in Calw in der Kategorie „Schlagzeug“ einen 1. Preis mit 24 Punkten erreicht.",
    "newsJugendMusiziertP2": "Wir gratulieren sehr herzlich zu diesem tollen Erfolg und wünschen euch weiterhin viel Spaß mit der Musik!",
    "newsKonzertHeadline": "Benefizkonzert Heeresmusikkorps Ulm",
    "newsKonzertSideInfo": "Heeresmusikkorps Ulm in Gingen",
    "newsKonzertP1": "Ein überragender Konzertabend fand am 19. November in der neuen BOSIG-Hohensteinhalle statt. Das Ulmer Heeresmusikkorps spielte in der hervorragend besuchten Halle ein kurzweiliges Konzert auf höchstem Niveau.",
    "newsKonzertP2": "Angefangen haben die Ulmer mit dem Graf Zeppelin Marsch von Carl Teike. Anschließend ging es direkt zum ersten Höhepunkt des Abends über: bei Fantasia von Claude T. Smith überzeugte StFw Jochen Müller auf dem Solo-Alt-Saxophon. Bei der folgenden Ouvertüre Ruslan & Ludmilla von Glinka konnte das gesamte Orchester seine geballte Power präsentieren. Poema Alpestre von Franco Cesarini überzeugte mit großer Abwechslung. Nach der Pause gab es eine deutsche Erstaufführung: Fanfare, Hymn und FInale von Peter Boyer. Weitere Sücke waren Silverando von Bruce Broughton und der Viktoriamarsch von Emil Neumann, bevor es zum abschließenden Musical-Highlight Musical Milestones arrangiert von Jörg Murschinski über ging. Dabei konnten die Ulmer Soldaten nochmals ihre Qualitäten beim Wechsel zwischen konzertanten und Popmusik-Stellen unter Beweis stellen. Die zahlreichen Zuhörer in den vollen BOSIG-Hohensteinhalle forderten völlig zurecht zwei Zugaben, die mit Hoch Heidecksburg und der deutschen Nationalhymne auch gegeben wurden.",
    "newsKonzertP3": "Der gesamte Erlös des Konzerts kommt dem neuen Hospiz in Geislingen zugute.",
    "newsKonzertExtHeadline": "Konzert in Westerstetten",
    "newsKonzertExtSideInfo": "Am Samstag, 25. November waren wir beim Musikverein Westerstetten zu Gast",
    "newsKonzertExtP1": "Der Musikverein Westerstetten veranstaltete Ende November sein Jahrskonzert, bei dem wir dieses mal zu Gast sind. Da unsere neue Gingener Halle noch im Bau ist, konnten wir keine Jahresfeier im konzertanten Rahmen durchführen, und so war das Jahreskonzert des MV Westerstetten unser einziges Konzert im zweiten Halbjahr.",
    "newsKonzertExtP2": "Unser musikalisches Programm führte uns quer durch die Welt der Märchen und Sagen, welches das trotz plötzlichen Wintereinbruchs zahlreich erschienene Publikum überzeugen konnte.",
    "newsMusikerhockHeadline": "Musikerhock",
    "newsMusikerhockSideInfo": "Unser Musikerhock findet am Sonntag, 12. Juni ab 11 Uhr auf dem Rathausvorplatz statt.",
    "newsMusikerhockP1": "Bei Musik unserer Jugendgruppe, Jugendkapelle, dem Musikverein Söhnstetten und unserer Aktiven Kapelle servieren wir neben den Klassikern auch Weißwurst (vormittags) und ab Mittags Schweinebraten, Kutteln und Chilli sin carne.",
    "newsMusikerhockP2": "Wir freuen uns auf viele Besucher!",
    "newsSerenadeHeadline": "Sommerserenade",
    "newsSerenadeSideInfo": "Sommerabend mit kühlem Bier, exotischen Cocktails, Fingerfood und vor allem stimmungsvoller Blasmusik!",
    "newsSerenadeP1": "Am Freitag, den 7. Juli fand bei uns im Garten des Musikerheims „Summer, Night & Music“ statt. Zunächst unsere Jugendensembles und anschließend die Aktive Kapelle haben mit passender Musik den Sonnenuntergang zu einem kleinen Erlebnis werden lassen. Bei Cocktails an der Bar und einem wunderschönen lauen Sommerabend konnten unsere Gäste den besuch genießen.",
    "newsSerenadeP2": "Ab etwa 20 Uhr wird unsere Jugend mit einigen sommerlichen Klängen den musikalischen Abend einläuten. Im Anschluss wird unsere Aktive Kapelle übernehmen und in die einsetzende Dunkelheit hinein spielen.",
    "newsSerenadeP3": "Anschließend servieren wir an der Bar noch verscheidene Drinks.",
    "newsSerenadeP4": "Wir freuen uns auf viele Besucher!",
    "newsJugendHeadline": "Kinder-Mitmachkonzert",
    "newsJugendSideInfo": "Das Geheimnis der verlorenen Noten",
    "newsJugendP1": "Vergangenen Sonntag fand unser erstes Kinder-Mitmachkonzert statt. Wir freuen uns, dass zahlreiche Kinder mit ihren Eltern den Weg zu uns gefunden haben, um unsere Instrumente zu hören, zu entdecken und auch selbst auszuprobieren. Uns allen hat es großen Spaß gemacht und wir hoffen, dass wir den Ein oder Anderen für unsere Instrumente und die Musik begeistern konnten.",
    "newsJugendP2": "Wir freuen uns schon auf die Umsetzung vieler neuer gewonnener Ideen!",
    "newsMarschwsHeadline": "Marschmusik-Workshop",
    "newsMarschwsSideInfo": "Um dir als Zuschauer auch bei Festzügen ein tolles musikalisches und optisches Erlebnis zu bieten, wollen wir als Musikverein unsere Fähigkeiten in der Marschmusik verbessern. Deshalb bitten wir dich um deine Spende für einen Marschmusik-Workshop.",
    "newsMarschwsP1": "Eine Musikkapelle kann bekanntlicherweise nicht nur die Zuhörer im Festzelt oder Konzertsaal unterhalten, sondern auch auf Festzügen mit klingendem Spiel für den richtigen musikalischen Rahmen sorgen. Während wir  vom Musikverein Gingen für sehr gute Musik auf der Bühne bekannt sind, möchten wir auch in der Marschmusik das Beste für unser Publikum geben und ein musikalisches wie auch optisches Highlight sein. Und dazu gehört es nicht allein, dass man die richtigen Töne trifft, sondern zum Beispiel auch, dass alle Musiker die Instrumente in der gleichen Weise halten, sie im exakt selben Moment an den Mund führen und die Marschordnung eingehalten wird und dies auch bei „Gegenverkehr“, Hindernissen oder in Kurven. Dieses Jahr wird dies besonders wichtig, denn wir spielen an drei großen Festzügen mit - einer davon im Ausland, nämlich in Haiming/Tirol.",
    "newsMarschwsP2": "Um auf dem Gebiet der Marschmusik richtig gut zu werden, planen wir am Samstag, dem 13.Mai 2023 einen Marschmusik-Workshop mit Rainer Mäder, einem ehemaligen Oberstabsfeldwebel beim Heeresmusikkorps Ulm, der jetzt als Dozent für Marschmusik in ganz Baden-Württemberg bekannt ist. Die Kosten für den Workshop belaufen sich auf 890 Euro für den Dozenten und weitere 429 Euro für die Anmietung der Kuchener Ankenhalle, da unsere örtliche Halle, die Gingener Hohensteinhalle abgerissen wurde und ein Neubau erst ab 2024 bereit steht.",
    "newsMarschwsP3": "Die Gesamtkosten von 1319 Euro sind natürlich für einen kleinen Verein wie den unseren ganz erheblich, doch wir sind sicher, dass sie für unseren Vereinszweck, die Förderung der musikalischen Kultur im ländlichen Raum geeignet und gerechtfertigt sind. Deshalb wären wir für eine Unterstützung sehr dankbar. ",
    "newsVideoHeadline": "Unsere Reise",
    "newsVideoSideInfo": "Im Rahmen eines Wettbewerbs der schweizer Volks-Pop-Band Fäaschtbänkler haben wir ein Video produziert rund um deren Hit \"Unsere Reise\", das auch die Reise unseres Vereins gut repräsentiert. Viel Spaß beim anschauen!",
    "newsDirigentHeadlineMain": "Bist du Dirigentin oder Dirigent? Dann bist du hier richtig!",
    "newsDirigentSideInfo": "Nach 15 Jahren als musikalischer Leiter des Musikvereins Gingen/Fils beendet unser bisheriger Dirigent Gunnar Merkert zum 31.12.2024 sein Engagement in unserem Verein. Eine berufliche Veränderung erlaubt ihm nicht mehr, die Proben am Freitagabend zu leiten.",
    "newsDirigentHeadline2": "Deshalb suchen wir jetzt dich!",
    "newsDirigentHeadline3": "Du",
    "newsDirigentP01": "bist Dirigentin oder Dirigent und freust dich ab 1. Januar 2025 auf eine neue Aufgabe als musikalische/r Leiter/in unserer Aktiven Kapelle. Optional möglich wäre die Leitung der Jugendkapelle, die jedoch auch eine Musikerin des Vereins mit C1-Kurs zu übernehmen bereit wäre.",
    "newsDirigentP02": "möchtest dein Publikum mit vielfältiger Musik begeistern und dein Orchester musikalisch voranbringen.",
    "newsDirigentP03": "willst gemeinsam mit uns eine musikalische Vision entwickeln und umsetzen.",
    "newsDirigentP13": "hast freitagabends Zeit.",
    "newsDirigentHeadline4": "Wir",
    "newsDirigentP04": "sind ein Musikverein mit 55 Musiker/innen und einer Auftrittsstärke von bis zu 45 Musiker/innen in der Aktiven Kapelle. Unsere Jugendkapelle umfasst derzeit ca. 20 Musizierende.",
    "newsDirigentP05": "machen konzertante und traditionelle Blasmusik und moderne Unterhaltungsmusik.",
    "newsDirigentP06": "besitzen ein eigenes Vereinsheim. Damit sind wir sehr flexibel, was kurzfristig angesetzte zusätzliche Probentermine angeht. Konzerte führen wir meist in der sehr schönen und akustisch und optisch sehr ansprechenden neuen BOSIG-Hohensteinhalle in Gingen durch.",
    "newsDirigentP07": "haben traditionell einen vorbildlichen Probenbesuch. Du kannst dich darauf verlassen, dass die Aktive Kapelle pünktlich um 20 Uhr vollständig anwesend und spielbereit ist.",
    "newsDirigentP08": "haben ein vollständiges und gut eingespieltes Vorstandsteam, das dich in allen Belangen unterstützen wird. Ein Vizedirigent (oder sogar dessen Vertreter) ist bereit einzuspringen, wenn es dir einmal nicht möglich ist, eine Musikprobe zu leiten.",
    "newsDirigentP09": "Unsere musikalischen Highlights im Jahresverlauf sind ein Frühjahrskonzert, bei dem der Schwerpunkt auf konzertanter Blasmusik liegt, eine abendliche Sommerserenade im Juli und ein Kurkonzert in Bad Boll im September mit gehobener Unterhaltungsmusik. Sehr beliebt ist unsere sehr gut besuchte Jahresfeier Anfang Dezember. An ihr kommen neben konzertanten Werken auch Stücke der traditionellen und modernen Blasmusik sehr gut bei unserem Publikum an. Weitere eigene Veranstaltungen sind der „Musikerhock“ im Juni und optional ein Herbstfest im September.",
    "newsDirigentP10": "Selbstverständlich musizieren wir aber auch sehr gerne bei auswärtigen Veranstaltungen - teils konzertant oder unterhaltsam auf der Bühne, gerne aber auch mit Marschmusik, zum Beispiel beim Stadtfest in Eislingen oder dem Göppinger Maientag oder letztes Jahr in Haiming (Tirol). Du siehst, wir sind vielseitig veranlagt und flexibel und mit uns wird es dir nicht schwerfallen, dein Publikum zu überzeugen!",
    "newsDirigentHeadline5": "Bist du interessiert?",
    "newsDirigentP11": "Dann schreibe uns doch eine E-Mail und gerne vereinbaren wir auch einen Telefontermin, an dem du unserem 1. Vorsitzenden alle deine Fragen stellen kannst.",
    "newsDirigentP12": "Hier unsere Kontaktdaten:<br>vorstand@mvgingen.de<br>Telefon mobil: 015140431569 (1. Vorsitzender Markus Windstoßer)",
    "newsDirigentHeadline6": "Wir freuen uns, von dir zu hören!",
    //Aktive Kapelle
    "kapelleAktiveHeadline": "Aktive Kapelle",
    "kapelleAktiveSideInfoP1": "Unser Repertoire erstreckt sich über die gesamte Bandbreite der Blasmusik, von konzertanten, klassischen Werken über moderne Kompositionen bis hin zur leichten und volkstümlichen Unterhaltung sowie Rock und Pop. Oberste Priorität hat dabei die musikalische Qualität und die gute Unterhaltung der Zuhörer.",
    "kapelleAktiveP1": "Das musikalische Vereinsjahr ist geprägt von Konzerten, Wertungsspielen, Mitwirkung bei Veranstaltungen der Gemeinde und der Kirchengemeinden sowie Auftritten bei gesellschaftlichen Ereignissen in der ganzen Region.",
    "kapelleAktiveP2": "Du spielst bereits ein Instrument und hast Interesse bei uns mitzuspielen? Wende dich gerne an unseren <a href=\"/kontakt.html\">Musikervorstand</a>.",
    "kapelleAktiveP3": "Die Aktive Kapelle probt freitags von 20 Uhr bis 22 Uhr in unserem Musikerheim.",
    //Musiker
    "kapelleMusikerHeadline": "Musiker der Aktiven Kapelle",
    "kapelleMusikerP1": "In unserer Aktiven Kapelle spielen derzeit etwa 55 Musiker alle Genres der modernen und traditonellen Blasmusik. Wir spielen in der Oberstufe.",
    "kapelleMusikerHolzHeadline": "Holzbläser",
    "kapelleMusikerHolzDes": "Die Holzblasinstrumente bilden eine der beiden großen Bläsergruppen in der Blasmusik und zeichnen sich meist durch einen weichen Klang aus. Entgegen dem Namen sind diese Instrumente nicht alle aus Holz gebaut, ähneln sich aber in der Tonerzeugung. Dazu wird entweder eine Kante oder ein oder zwei Rohrblätter angeblasen. Die Tonhöhe wird durch verschließen von Löchern im Instrument bestimmt.",
    "kapelleMusikerFluteHeadline": "Flöte",
    "kapelleMusikerFluteNames": "Annika Steegmaier<br>Carmen Kuhn<br>Julia Riesner<br>Julia Wörz<br>Michaela Schauer<br>Sarah Altenberger<br>Svenja Schauer<br>Vanessa Braekau",
    "kapelleMusikerOboeHeadline": "Oboe",
    "kapelleMusikerOboeNames": "Katja Rieger",
    "kapelleMusikerClarinetHeadline": "Klarinette",
    "kapelleMusikerClarinetNames": "Angelika Steegmaier<br>Antje Walther<br>Florian Mank<br>Katharina Vetter<br>Lara Fetzer<br>Mario Deger<br>Sigrid Riesner<br>Stefanie Honold<br>Tina Windstoßer<br>Wolfgang Mann",
    "kapelleMusikerSaxophonHeadline": "Saxophon",
    "kapelleMusikerSaxophonNames": "Annika Mauz<br>Elke Pflüger<br>Florian Stadelmaier<br>Hannah Adametz<br>Lena Mann<br>Nadine Mollenkopf<br>Siegfried Mann<br>Tamara Stöckle",
    "kapelleMusikerBassoonHeadline": "Fagott",
    "kapelleMusikerBassoonNames": "Yvonne Windstoßer",
    "kapelleMusikerBlechHeadline": "Blechbläser",
    "kapelleMusikerBlechDes": "Die Blechblasinstrumente bilden die zweite Bläsergruppe und haben oft einen kantigeren Klang als die Holzbläser. Die Mundstücke der Instrumente sind trichterförmig und ermöglichen dem Musiker durch schwingende Lippen die Tonerzeugung. Durch Änderungen an dieser Schwingung kann der Ton geändert werden. Zusätlich gibt es Ventile oder bei der Posaune einen Zug, um die Tonhöhe zu beeinflussen.",
    "kapelleMusikerTrumpetHeadline": "Trompete",
    "kapelleMusikerTrumpetNames": "Eva Riesner<br>Jonas Steegmaier<br>Lothar Keller<br>Martin Mann<br>Simon Roos<br>Steffen Herchel",
    "kapelleMusikerFlugelhornHeadline": "Flügelhorn",
    "kapelleMusikerFlugelhornNames": "Harald Riesner<br>Lukas Mann<br>Markus Windstoßer<br>Sarah Riesner",
    "kapelleMusikerHornHeadline": "Horn",
    "kapelleMusikerHornNames": "Bernhard Putz<br>Karl-Heinz Kühlwein<br>Leo Rieger",
    "kapelleMusikerTromboneHeadline": "Posaune",
    "kapelleMusikerTromboneNames": "Melanie Ihli<br>Michael Windstoßer",
    "kapelleMusikerTenorhornHeadline": "Tenorhorn/ Bariton",
    "kapelleMusikerTenorhornNames": "Jan Steegmaier<br>Steffen Stumpp",
    "kapelleMusikerTubaHeadline": "Tuba",
    "kapelleMusikerTubaNames": "Rudolf Köberl<br>Timo Pomeissl",
    "kapelleMusikerDrumsHeadline": "Schlagwerk",
    "kapelleMusikerDrumsDes": "Das Schlagwerk, oder auch Schlagzeug, ist für die rhythmische Grundlage im Blasorchester verantwortlich. Neben dem wohl bekanntesten Instrument, dem Drum Set, gibt es eine Vielzahl weiterer Instrumente, die im Schlagwerk beheimatet sind. Dazu gehören diverse Percussioninstrumente wie Bongos, Triangel oder Cabasa, Becken, Malletinstrumente wie das Glockenspiel, Xylophon oder Röhrenglocken sowie die Pauken, auch Timpani genannt. Außerdem wird das Schlagwerk regelmäßig um außergewöhnliche 'Instrumente' erweitert, je nach Bedarf der gespielten Literatur.",
    "kapelleMusikerDrumsNames": "Fynn Streubel<br>Jürgen Gießler<br>Lukas Stumpp<br>Marc Pflüger<br>Marcel Mauz<br>Marcel Zeller<br>Roland Vetter",
    //Dirigent
    "kapelleDirigentHeadline": "Unser Dirigent",
    "kapelleDirigentP1": "Gunnar Merkert studierte Orchestermusik mit Hauptfach Fagott an der Musikhochschule Mannheim. Ab 1991 lehrte er an den Musikschulen Reutlingen, Bad Urach und Denkendorf. Aktuell ist er als Musikschullehrer für Klarinette und Saxophon und Leiter mehrerer Bläserklassen und Ensembles an der Musikschule Neckartailfingen, Bad Urach und Metzingen tätig. Bei uns leitet er die Aktive Kapelle sowie die Jugendkapelle.",
    "kapelleDirigentP2": "1995 absolvierte Gunnar Merkert den C3-Lehrgang (Leitung von Blasorchestern) und anschließend den B-Lehrgang (Leitung von Blasorchestern und Ausbildung von Jungbläsern) an der Bundesakademie für musikalische Jugendbildung in Trossingen. Privaten Dirigierunterricht hatte er bei Miguel Angel Monroy und er nahm an einer Vielzahl von Fortbildungen namhafter Dirigenten und Komponisten teil. Von 2015 bis 2017 absolvierte er ein Privatstudium bei Prof. Albrecht Holder (Hochschule für Musik Würzburg). Seit seiner Ausbildung zum Dirigenten im Jahr 1995 leitete er in verschiedenen Musikvereinen Stamm- und Jugendblasorchester. Seit 2004 ist Gunnar Merkert Juror und Dozent für Wertungsspiele und D-Lehrgänge beim Blasmusikverband Baden-Württemberg und seit 2015 ebenfalls Kreisverbandsdirigent des Blasmusikkreisverbands Göppingen. Weiterhin spielt er im Duo „La vers de bois“, im Trio „Flagottavier“ und in der Vergangenheit viele Jahre im Sinfonischen Blasorchester Ulm.",
    //Jugend Ausbildung
    "jugendAusbildungHeadline": "Jugendausbildung",
    "jugendAusbildungSideInfoP1": "Die Jugendarbeit ist Grundlage für ein in die Zukunft gerichtetes und erfolgreiches Vereinsleben. Wir fördern und organisieren die musikalische Ausbildung des Nachwuchses durch qualitativ hochwertigen Musikunterricht in Zusammenarbeit mit der Kolping-Musikschule Süßen und durch öffentliche Auftritte der Jugendgruppe und der Jugendkapelle.",
    "jugendAusbildungSideInfoP2": "Neben den musikalischen Aktivitäten organisieren wir regelmäßig Probenwochenenden und weitere Freizeitunternehmungen. Bei Interesse an einer Jugendausbildung bei uns oder weiteren Fragen dazu wenden Sie sich bitte an unseren <a href=\"/kontakt.html\">Jugendleiterin</a>.",
    "jugendAusbildungKonzeptHeadline": "Konzept",
    "jugendAusbildungKonzeptP1": "Unser 3-Stufen-Programm umfasst die musikalische Ausbildung der Jungmusiker, beginnend ab dem Kindergartenalter. Auch ein Einstieg bei einer der späteren Stufen ist möglich.",
    "jugendAusbildungKonzeptP2Number": "1",
    "jugendAusbildungKonzeptP2Headline": "Musikalische Früherziehung / „Singen – Bewegen – Sprechen“",
    "jugendAusbildungKonzeptP2Text": "In der elementaren Musikerziehung werden die Sinne der Kinder (3 – 6 Jahre) für die Musik geweckt und bildet somit einen wichtigen Bestandteil der gesamten Entwicklung der Kinder.",
    "jugendAusbildungKonzeptP3Number": "2",
    "jugendAusbildungKonzeptP3Headline": "Blockflötenunterricht",
    "jugendAusbildungKonzeptP3Text": "Ab ca. 6 – 7 Jahren geht den Kindern schon vieles leichter und schneller von der Hand, da die Aufnahmefähigkeit zum Erlernen von Neuem durch den Besuch der Schule bereits ausgeprägter ist. An dieser Stelle setzt unser Blockflötenunterricht an, welcher den Grundstein für eine weitere Ausbildung am Instrument bildet.",
    "jugendAusbildungKonzeptP4Number": "3",
    "jugendAusbildungKonzeptP4Headline": "Ausbildung am Instrument",
    "jugendAusbildungKonzeptP4Text": "Im Alter von ca. 8 – 9 Jahren, dies entspricht dem Beginn der 3. – 4. Schulklasse, beginnt die Ausbildung am Instrument. In Zusammenarbeit mit der Kolping-Musikschule Süßen wird eine bedarfsorientierte Ausbildung durchgeführt. Außerdem besuchen die Jungmusiker die Jugendlehrgänge des Kreisverbands. Auch ein Ausbildungsbeginn mit höherem Alter ist möglich.",
    "jugendAusbildungInstrumenteHeadline": "Instrumente",
    "jugendAusbildungInstrumenteP1": "Diese Intrumente können bei uns erlernt werden",
    "jugendAusbildungInstrumente01Name": "Trompete",
    "jugendAusbildungInstrumente02Name": "Posaune",
    "jugendAusbildungInstrumente03Name": "Tenorhorn/Bariton",
    "jugendAusbildungInstrumente04Name": "Waldhorn",
    "jugendAusbildungInstrumente05Name": "Tuba",
    "jugendAusbildungInstrumente06Name": "Flöte",
    "jugendAusbildungInstrumente07Name": "Oboe",
    "jugendAusbildungInstrumente08Name": "Klarinette",
    "jugendAusbildungInstrumente09Name": "Saxophon",
    "jugendAusbildungInstrumente10Name": "Schlagzeug",
    "jugendAusbildungInstrumente01InfoText": "Die Trompete hat einen knackigen, hellen Klang und den höchsten Ton aller Blechblasinstrumente und wird daher häufig in die Oberstimme sowie für Signale eingesetzt. Eng verwandt ist das Flügelhorn, das einen weicheren Klang als die Trompete bietet. Ein späterer Wechsel zwischen diesen Instrumenten ist ohne großen Aufwand möglich. Außerdem gibt es das Kornett, das sich durch seine engere Bauweise für Kinder anbietet.",
    "jugendAusbildungInstrumente02InfoText": "Die Posaune hat einen kräftigen, wohlklingenden Klang in der mittleren Tonhöhe und hat ein breites Spektrum an Einsatzmöglichkeiten. Eine Besonderheit ist die Möglichkeit über die Töne zu \"gleiten\" und so spezielle \"Glissando\"-Effekte in der Musik zu erzielen, was kein anderes Instrument im Blasorchester kann.",
    "jugendAusbildungInstrumente03InfoText": "Das Tenorhorn zeichnet sich durch einen präzisen und hellen Klang in der mittleren Tonhöhe aus und kann daher sowohl in der Melodie als auch der Harmonie gut eingesetzt werden. Das eng verwandte Bariton unterscheidet sich durch einen weicheren und inbesondere in den tieferen Tönen volleren Klang.",
    "jugendAusbildungInstrumente04InfoText": "Das Horn hat einen weicheren, weniger kantigen und wärmeren Klang als eine Trompete. Dadurch liegt es klanglich zwischen den Holz- und Blechbläsern und kann je nach Bedarf die Hölzer oder das Blech im Klang ergänzen oder mit seinem einzigartigen Klang solistische, eigene Stimmen übernehmen.",
    "jugendAusbildungInstrumente05InfoText": "Die Tuba bildet als tiefstes Blasinstrument im Orchester mit ihrem kräftigen und breiten Klangteppich die unverzichtbare Grundlage für alle anderen Instrumente. Sie hält die einzelnen Stimmen der anderen Instrumente klanglich zusammen, die sonst als Einzelstimmen keine zusammenhängende Musik ergeben würden. Für Kinder und Jugendliche bietet sich ein Einstieg auf einer Kinder-Tuba an.",
    "jugendAusbildungInstrumente06InfoText": "Die Querflöte hat einen scharfen, hellen Klang und eignet sich daher für Oberstimmen, Läufe und Verzierungen. Mit der Picollo-Flöte gibt es eine kleinere Version, die das höchste Instrument im Blasorchester ist. Der Einstieg findet jedoch immer auf der Querflöte statt. Wir bilden die Flöte derzeit nicht aus.",
    "jugendAusbildungInstrumente07InfoText": "Die Oboe zeichnet sich durch einen hellen, samtigen Klang aus. Durch viele Obertöne wirkt die Oboe zum Teil auch leicht kreischend und beschert ihr ihren ganz typischen Klang. Ähnlich der Flöte wird die Oboe für Oberstimmen, Läufe und Verzierungen eingesetzt. Wir bilden die Oboe derzeit nicht aus.",
    "jugendAusbildungInstrumente08InfoText": "Die Klarinette hat einen sehr umfangreichen Klangchrakter sowie einen ebenfalls sehr umfangreichen Tonumfang. Sie kann daher für einen weichen Klangteppich als Grundlage der Musik genauso eingesetzt werden wie für präzise Melodiestimmen, aber auch für ausdrucksvolle Passagen. Mit der Bassklarinette ist auch eine tiefe Variante geläufig, ein Einstieg findet jedoch auf der normalen Klarinette statt.",
    "jugendAusbildungInstrumente09InfoText": "Das Saxophon hat einen kräftigen und variablen Klang und ein für ein Holzblasinstrument großen Lautstärkeumfang. Es stellt oft ein Bindeglied von den Holzbläsern zu den Blechbläsern, insbesondere den Hörnern, dar. Neben der geläufigsten Variante, dem Alt-Saxophon, sind im Blasorchester auch die tieferen Varianten Tenor- und Baritonsaxophon besetzt, die ein Bindeglied insbesondere zu den Tenorhörnern und Baritonen sind. Wir bilden das Saxophon derzeit nur über einen Einstieg auf der Klarinette aus.",
    "jugendAusbildungInstrumente10InfoText": "Das Schlagzeug oder allgemeiner auch Schlagwerk besteht neben dem Drum Set auch aus Percussion-, Mallet- und Timpaniinstrumenten. Das Schlagwerk hat entscheidenden Einfluss auf die Rhythmik, Lautstärke und das Tempo der Musik. Nicht selten wird das Blasorchester vom Schlagwerk zusammengehalten und geführt. Ungewöhnliche Effekte und Töne werden vom Schlagwerk mit unterschiedlichsten Instrumenten oder instrument-ähnlichen Dingen erzeugt.",
    "jugendAusbildungForderungHeadline": "Förderung",
    "jugendAusbildungForderungP1": "Zum Erhalt der Spielfähigkeit der einzelnen Orchester besteht bei uns ein Förderkonzept zur Förderung sogenannter Mangelinstrumente.",
    "jugendAusbildungForderungP2": "Aktuell sind dies vor allem die Instrumente am tiefen Blech wie Waldhorn, Tenorhorn/Bariton, Posaune und Tuba. Entscheidet sich ein Schüler für eines dieser Instrumente wird ihm für die Dauer von drei Jahren ein kostenloses Leihinstrument zur Verfügung gestellt. Weiterhin wird die Ausbildung in der Musikschule über einen Zeitraum von sechs Jahren bezuschusst.",
    "jugendAusbildungForderungP3": "Auch für weitere Instrumente, welche essentiell für die Spielfähigkeit der Kapellen sind, wird die Ausbildung in der Musikschule über eine Dauer von vier Jahren bezuschusst.",
    "jugendAusbildungInfoHeadline": "Weitere Informationen und Anmeldung",
    "jugendAusbildungInfoP1": "Für weitere Informationen und die Anmeldung wenden Sie sich bitte an unseren Musikervorstand <a href=\"/kontakt.html\">Jonas Steegmaier</a> oder unsere Jugendleiterin <a href=\"/kontakt.html\">Eva Riesner</a>.",
    "jugendAusbildungInfoDoc1": "Ausbildung beim MVG",
    "jugendAusbildungInfoDoc2": "Anmeldung Instrumentalausbildung",
    "jugendAusbildungInfoDoc3": "Anmeldung Blockflötenunterricht",
    "jugendAusbildungInfoDoc4": "Anmeldung musikalische Früherziehung",
    "jugendAusbildungInfoDoc5": "Mitgliedsantrag",
    "jugendMoreInfo": "Weitere Informationen und Anmeldung",
    //musikalische Früherziehung
    "jugendMusikfruehHeadline": "Musikalische Früherziehung",
    "jugendMusikfruehSideInfoP1": "In der elementaren Musikerziehung werden die Sinne der Kinder (3 – 6 Jahre) für die Musik geweckt und bildet somit einen wichtigen Bestandteil der gesamten Entwicklung der Kinder.",
    "jugendMusikfruehP1": "Die musikalische Früherziehung findet in den Kindergärten statt und erfolgt nach dem Programm „Musik-Fantasie“ oder dem vom Land Baden-Württemberg geförderten Projekt „Singen – Bewegen – Sprechen“. <a href='http://www.kindergaerten-bw.de/,Lde/Singen_Bewegen_Sprechen+_SBS_'>Singen – Bewegen – Sprechen</a> ist ein deutschlandweit einmaliges musikpädagogisches Bildungsprogramm, das Kinder im Kindergartenalter ganzheitlich fördert durch Singen - Bewegen und Sprechen. Nach dem Programm „Musik-Fantasie“ werden die Kinder im Bereich Rhythmus, Noten- und Instrumentenkunde ausgebildet.",
    "jugendMusikfruehP2": "Beide Programme stehen unter der Leitung von Angelika Steegmaier. Information und Anmeldung erfolgen in den Kindergärten.",
    //Blockflöten
    "jugendBlockfloetenHeadline": "Blockflötenunterricht",
    "jugendBlockfloetenSideInfoP1": "Der Blockflötenunterricht für Kinder ab einem Alter von etwa 6 bis 7 Jahren bildet den Grundstein für eine weitere Ausbildung an einem Instrument.",
    "jugendBlockfloetenP1": "Der Blockflötenunterricht findet innerhalb des Betreuungsprogrammes der Hohensteinschule Gingen statt. Der Unterricht wird von Angelika Steegmaier mit dem Programm „Der Blockflötenspatz“ durchgeführt. Die Anmeldung erfolgt vor den Sommerferien über die Schule (Ausbildungsbeginn in der 2. Klasse) bzw. die Kindergärten (Ausbildungsbeginn in der 1. Klasse).",
    //Jugendgruppe
    "jugendJugendgruppeHeadline": "Jugendgruppe",
    "jugendJugendgruppeSideInfoP1": "Die Vorstufe zu unserer Jugendkapelle ist die Jugendgruppe. Bereits nach einem Jahr Instrumentalunterricht an der Musikschule wird hier das gemeinsame Spiel in der Gruppe unter der Leitung von Hannah Adametz erlernt.",
    "jugendJugendgruppeP1": "Für das erste gemeinsame Musizieren mit dem Instrument werden einstimmige und auch bereits einfache mehrstimmige Stücke gespielt. Oft handelt es sich um bekannte Kinderlieder, aber auch Filmmusik und Arrangements von moderner Popliteratur erfreuen sich bei den Jungmusikern großer Beliebtheit.",
    "jugendJugendgruppeP2": "Die Jugendgruppe tritt regelmäßig bei Veranstaltungen im Ort auf, um den Jungmusikern die Möglichkeit zu bieten, das Gelernte zu präsentieren. Insbesondere sind dies unser Musikerhock im Juni, das Herbstfest im September und die Jahresfeier im Dezember.",
    "jugendJugendgruppeP3": "Die Jugendgruppe probt dienstags von 18.30 Uhr bis 19.15 Uhr in unserem Musikerheim.",
    //Jugendkapelle
    "jugendJugendkapelleHeadline": "Jugendkapelle",
    "jugendJugendkapelleSideInfoP1": "Der Eintritt in die Jugendkapelle findet nach ca. 1 Jahren Instrumentalausbildung statt. Während der Zeit in der Jugendkapelle wird das Instrumentalspiel in der Gruppe weiter verbessert und die Jugendlichen werden auf die Aktive Kapelle vorbereitet. Weiterhin absolvieren unsere Jungmusiker in dieser Zeit ihre Leistungsabzeichen (D-Lehrgänge) beim Kreisverband Göppingen. Die Jugendkapelle wird von unserem Dirigenten Gunnar Merkert geleitet. ",
    "jugendJugendkapelleP1": "Um das gemeinsame Musizieren weiter zu verbessern werden mehrstimmige Stücke gespielt. Darunter finden sich viele Arrangements bekannter Melodien aller Genres. Erfreulicherweiße steigt die Zahl an konzertanten Stücken für Jugendkapellen an, sodass die Jungmusiker auch diese für die Blasmusik typische Musik spielen können.",
    "jugendJugendkapelleP2": "Die Jugendkapelle präsentiert sich regelmäßig auf unseren Vereinsfesten sowie Ortsfesten wie dem Gingener Kinderfest. Daneben sind die Jungmusiker bei der Jahresfeier und Konzerten unseres Vereins zu hören. Außerdem besucht die Jugendkapelle regelmäßig Jugendwertungsspiele, um sich der Kritik erfahrener Juroren zu stellen.",
    "jugendJugendkapelleP3": "Die Jugendkapelle probt freitags von 18.30 Uhr bis 19.30 Uhr in unserem Musikerheim.",
    //Außermusikalische Aktivitäten
    "jugendAusermusikalischHeadline": "Außermusikalische Aktivitäten",
    "jugendAusermusikalischSideInfoP1": "Das Konzept der Jugendausbildung besteht nicht nur aus musikalischer Ausbildung und Auftritten in einer unserer Orchester. Weitere außermusikalische Freizeitaktivitäten haben bei uns im Verein ihren festen Platz. Kegeln, Klettern, Minigolfen, Schlittschuhlaufen, Filmabende und das jährliche Grillfest werden für unsere Jungmusiker organisiert. All diese Aktivitäten fördern den gewünschten kameradschaftlichen Zusammenhalt und die sozialen Kompetenzen unserer Jugendlichen.",
    //Über uns
    "ueberunsHeadline": "Musikverein Gingen/Fils 1912 e.V.",
    "ueberunsSideInfoP1": "Unser Vereinsprofil ist ausgerichtet auf die kulturelle Bereicherung des Gemeindelebens, insbesondere im Bereich der Jugendförderung und -ausbildung. Unser Ziel ist die Pflege, Erhaltung und Weitervermittlung der Blasmusik.",
    "ueberunsP1": "Das musikalische Vereinsjahr ist geprägt von Konzerten, Wertungsspielen, Mitwirkung bei Veranstaltungen der Gemeinde und der Kirchengemeinden sowie Auftritten bei gesellschaftlichen Ereignissen in der ganzen Region.",
    "ueberunsP2": "Wir sind Mitglied im Kreisverband Göppingen, dieser wiederum gehört dem Blasmusikverband Baden-Württemberg an. Aus den Landesverbänden setzt sich der Dachverband, die Bundesvereinigung Deutscher Blas- und Volksmusikverbände, zusammen.",
    "ueberunsLeitspruch": "»O Musika so hold und rein,<br>Dir sei stets Hort am Hohenstein«",
    "ueberunsP3": "Unsere vollständige und umfangreiche Chronik, erstellt zum 100-jährigen Jubiläum 2012, kann in gedruckter Ausgabe zum Selbstkostenpreis von 47.50€ im Musikerheim erworben werden.",
    "ueberunsP4": "Unterstützen auch Sie uns mit ihrer Mitgliedschaft als passives Mitglied!",
    //Vorstand
    "vorstandHeadline": "Vorstand",
    "vorstandSideInfoP1": "Unser Vorstand besteht laut Satzung aus dem Vorsitzenden, dem stellvertretenden Vorsitzenden, dem Kassier, dem Wirtschaftsführer, dem Schriftführer und dem Jugendleiter. Erweitert wird dieser durch 3-5 Beisitzer.",
    "vorstandVorsitzenderDes": "Vorsitzender",
    "vorstandVorsitzenderName": "Markus Windstoßer",
    "vorstandStellvVorsitzenderDes": "stellv. Vorsitzender",
    "vorstandStellvVorsitzenderName": "Jonas Steegmaier",
    "vorstandKassierDes": "Kassier",
    "vorstandKassierName": "Harald Riesner",
    "vorstandWirtschaftDes": "Wirtschaftsführer",
    "vorstandWirtschaftName": "Siegfried Mann",
    "vorstandSchriftDes": "Schriftführer",
    "vorstandSchriftName": "Wolf-Dieter Fauth",
    "vorstandDirigentDes": "Dirigent",
    "vorstandDirigentName": "Gunnar Merkert",
    "vorstandJugendDes": "Jugendleiterin",
    "vorstandJugendName": "Eva Riesner",
    "vorstandBeisitzerDesM": "Beisitzer",
    "vorstandBeisitzerDesW": "Beisitzerin",
    "vorstandBeisitzerName1": "Tine Vetter",
    "vorstandBeisitzerName2": "Marcel Zeller",
    "vorstandBeisitzerName3": "Tamara Stöckle",
    "vorstandBeisitzerName4": "Roland Vetter",
    "vorstandSubHeadlineMu": "Musikerausschuss",
    "vorstandP1": "Um alle musikalischen Belange im Sinne aller Musikerinnen und Musiker zu entscheiden und umzusetzen gibt es neben dem Vereinsausschuss, der den gesamten Vorstand beinhaltet, auch noch einen Musikerausschuss, der von allen aktiven Musikern gewählt wird. Eine wichtige Aufage ist die Auswahl der gespielten Literatur. Dirigent, stellvertretender Dirigent, der Vorsitzende oder stellvertretende Vorsitzender sowie der Jugendleiter sind neben den gewählten Mitgliedern fester Bestandteil des Ausschuss.",
    //Musikerheim
    "musikerheimHeadline": "Musikerheim",
    "musikerheimSideInfoP1": "Ein Platz, an dem man sich gerne niederlässt. Unser Vereinsheim wurde ab 1965 in großartiger Eigenleistung erbaut und 2011 aufwändig renoviert. Es ist das Stammlokal unserer Musiker, zugleich aber auch Treffpunkt für Jung und Alt, um bei kleinen Speisen und erfrischenden Getränken zusammenzusitzen. Das Haus mit einer gemütlichen Terrasse und der angrenzenden Wiese ist eine wunderbare Lokalität für einen Früh- oder Dämmerschoppen am Sonntag. Schauen Sie einfach mal vorbei!",
    "musikerheimP1": "Öffnungszeiten:",
    "musikerheimP2": "Sonntag",
    "musikerheimP3": "10 Uhr - 12 Uhr",
    "musikerheimP4": "18 Uhr - 21 Uhr",
    //Bilder
    "bilder24Headline": "Bilder 2024",
    "bilder24Sub1Headline": "Frühjahrskonzert",
    "bilder24Sub1Des": "Das Frühjahrskonzert war das erste Konzert in der neuen Bosig-Hohensteinhalle, bei dem wir neben einen schönen Märschen und Polkas auch die 80er Kult(Tour) 2 und ein Bon Jovi Medley präsentierten.",
    "bilder24Sub2Headline": "Eröffnungen Halle",
    "bilder24Sub2Des": "Die Eröffnung der neu gebauten Mehrzweckhalle Bosig-Hohensteinhalle in Gingen haben wir musikalisch umrahmt.",
    "bilder24Sub3Headline": "Maihock",
    "bilder24Sub3Des": "Der erste Unterhaltungsauftritt der Saison fand statt im Rahmen der Maibaumstellung durch den Schwäbischen Albverein und die Feuerwehr. Sowohl unsere Jugend als auch die Aktive Kapelle haben auf dem Rathausvorplatz für gute Unterhaltung gesorgt.",
    "bilder24Sub4Headline": "Schnittlingen",
    "bilder24Sub4Des": "Im Festzelt in der Ortsmitte hat der MV Schnittlingen wieder zum Straßenfest am Vatertag eingeladen. Bei bestem Wetter haben wir den Nachmittag über das Festzelt unterhalten.",
    "bilder23Headline": "Bilder 2023",
    "bilder23Sub1Headline": "Konzert Wangen",
    "bilder23Sub1Des": "Am 23. April waren wir zu Gast in der Gemeindehalle in Wangen bei Göppingen, um gemeinsam mit den Gastgebern ein Konzert zu spielen. Mit dem Konzertmarsch \"Arsenal\" haben wir den Abend in der bis auf den letzten Platz vollen Gemeindehalle eröffnet. Anschließend haben wir die Zuhörer mit \"El Camino Real\" und der \"New York Overture\" nach Spanien und die Vereinigten Staaten geführt. Außerdem gab es von uns flotte Rhythmen bei \"Bond...James Bond\" und \"Eighties Flashback\" zu hören.",
    "bilder23Sub2Headline": "Maihock",
    "bilder23Sub2Des": "Die Unterhaltungssaison haben wir im Rahmen der Maibaumaufestellung des Schwäbischen Albvereins eröffnet.",
    "bilder23Sub3Headline": "Marschmusik- workshop",
    "bilder23Sub3Des": "Anfang Mai haben wir uns auf anstehende Umzüge und Marschauftritte vorbereitet. Unter der Anleitung des ehemaligen Oberstabsfeldwebels des Heeresmusikkorps Ulm Rainer Mäder haben wir einen ganzen Samstag über Marschtechnik, Signale und die richtige Haltung geübt.",
    "bilder23Sub4Headline": "Ehrenmitgliedertag",
    "bilder23Sub4Des": "An einem Wochenende Anfang Mai haben wir unsere Ehrenmitglieder zu einem gemütlichen Zusammensein ins Musikerheim eingeladen. Gemeinsam wurde bei Kaffee und Kuchen ein Video vom Zapfenstreich von unserem 100 jährigen Jubiläum 2012 angeschaut. Im Anschluss hat die Aktive Kapelle noch ein kleines Ständchen gespielt.",
    "bilder23Sub5Headline": "Videodreh",
    "bilder23Sub5Des": "Einen ganzen Samstag lang war zum Erstaunen der Gingener eine immer größer werdende Musikertruppe außerhalb und innerhalb des Ortes unterwegs, um an verschiedenen Stellen Aufnahmen zu machen. Das Ergebnis war ein Video zum Titel der Fäaschtbänkler \"Unsere Reise\".",
    "bilder23Sub6Headline": "Musikerhock",
    "bilder23Sub6Des": "Unser traditioneller Musikerhock war bei bestem Wetter überragend besucht. Musikalisch umrahmt wurde der Sonntag vom Musikerverein Schnittlingen, unserer Jugend und unserer Aktiven Kapelle.",
    "bilder23Sub7Headline": "Schnapperfest",
    "bilder23Sub7Des": "Beim Schnapperfest rund um den Schnapperbrunnen in der Pfarrstraße haben wir in die Nacht hinein mit abwechslungsreicher Blasmusik unterhalten.",
    "bilder23Sub8Headline": "Kinder- Mitmachkonzert",
    "bilder23Sub8Des": "Bei einem Mitmachkonzert war nicht nur unsere Jugend gefragt, sondern auch die jungen Zuhörer, die das Geheimnis der verlorenen Noten lüften mussten. Außerdem konnten alle Instrumente ausprobiert werden.",
    "bilder23Sub9Headline": "Summer, Night & Music",
    "bilder23Sub9Des": "Unserer Sommerserenade haben wir einen neuen Namen verpasst: Summer, Night & Music. Dieser passt perfekt, beschreibt er doch treffend die Veranstaltung",
    "bilder23Sub10Headline": "Waldfest Haiming",
    "bilder23Sub10Des": "Das Highlight das Jahres war unser Vereinsausflug nach Tirol. Am Eingang des Öztals liegt der Ort Haiming, dessen Musikkapelle das weithin bekannte Waldfest ausrichtet. Die Gastgeber selbst, die Kapelle aus St. Johann und wir sind dazu unter Musik aus dem Ort hinaus zum Festplatz maschiert. Dort angekommen haben wir dann eine Stunde lang im Festzelt Stimmung gemacht.",
    "bilder23Sub11Headline": "Herbstfest",
    "bilder23Sub11Des": "Bei sonnigem und angenehmen Herbstwetter begrüßten wir unsere Gäste rund um unser Musikerheim",
    "bilder23Sub12Headline": "Konzert Westerstetten",
    "bilder23Sub12Des": "Da unsere Jahresfeier nur in kleinem Rahmen in den Räumlichkeiten des evangelischen Gemeindezentrums stattfinden konnte aufgrund der noch im Bau befindlichen Hohensteinhalle, fand unser Konzert im zweiten Halbjahr ausschließlich auswärts statt. Wir durften bei unseren Musikerfreunden aus Westerstetten zu Gast sein und präsentierten eine anspruchsvolle Auswahl rund um das Thema Märchen.",
    "bilder22Headline": "Bilder 2022",
    "bilder22Sub1Headline": "Hauptversammlung",
    "bilder22Sub1Des": "Zu Beginn des Jahres stand die jährliche Hauptversammlung an. Wir mussten unseren langjährigen Vorsitzenden Mario Deger aus dem Vorstand verabschieden, konnten aber unseren neuen Vorsitzenden Markus Windstosser begrüßen.",
    "bilder22Sub2Headline": "Frühjahrskonzert",
    "bilder22Sub2Des": "Unser erstes großes Konzert nach der Coronapause war ein großer Erfolg. Es wurde als Benefizkonzert für die Ukrainehilfe durchgeführt",
    "bilder22Sub3Headline": "Musikerhock",
    "bilder22Sub3Des": "Der Musikerhock war unser erstes großes Fest nach der Coronapause.",
    "bilder22Sub4Headline": "Gingener Festtage",
    "bilder22Sub4Des": "Gemeinsam mit den anderen Vereinen und der ganzen Gemeinde haben wir groß gefeiert.",
    "bilder22Sub5Headline": "Promenaden- konzert",
    "bilder22Sub5Des": "Ein kleines, aber feines Konzert im Kurpark Bad Boll.",
    "bilder22Sub6Headline": "Herbstfest",
    "bilder22Sub6Des": "Rund ums Musikerheim konnten wir bei schönem Herbstwetter viele Gäste begrüßen und mit besten Linsen und Spätzle verwöhnen.",
    "bilder22Sub7Headline": "Brass meets Vocal",
    "bilder22Sub7Des": "Im November haben wir in der katholischen Kirche in Gingen ein Konzert gegeben. Gemeinsam mit gesanglicher Unterstützung von Torsten Moll und Stefanie Martin konnten wir die Zuhörer in der komplett vollen St. Barabara Kirche überzeugen.",
    "bilder21Headline": "Bilder 2021",
    "bilder21Sub1Headline": "Wiederaufnahme Probenbetrieb",
    "bilder21Sub1Des": "Nach coronabedingter Winterpause konnten wir endlich wieder anfangen zu proben.",
    "bilder21Sub2Headline": "Probe im Garten",
    "bilder21Sub2Des": "Zum Saisonabschluss haben wir uns vor den Sommerferien nochmals für eine Probe im Garten unseres Musikerheims getroffen.",
    "bilder21Sub3Headline": "Herbsttreff",
    "bilder21Sub3Des": "Im Herbst haben wir in und um unser Musikerheim eingeladen und konnten auch endlich wieder zur Unterhaltung aufspielen.",
    "bilder20Headline": "Bilder 2020",
    "bilder20Sub1Headline": "Schnapperfasnet",
    "bilder20Sub1Des": "Zur Fasnetszeit 2020 fand unsere erste Schnapperfasnet statt. Mit DJ Suni und Chriss Reiser brodelte die Stimmung in der mit Main-Bar und Cocktail-Bar ausgestatteten Hohensteinhalle. Auf dem Dancefloor war durch perfektes Licht beste Stimmung!",
    "bilder20Sub2Headline": "Probenwochenende",
    "bilder20Sub2Des": "Zur Vorbereitung auf das anstehende Konzert fand zu Beginn des Jahres ein Probenwochenende der Aktiven Kapelle statt. Neben ausgiebigen Gesamt- und Registerproben kam auch die Kameradschaft nicht zu kurz.",
    "bilder20Sub3Headline": "Freiluftproben",
    "bilder20Sub3Des": "Coronabedingt konnten wir im Sommer des Jahres nicht in unserem Musikerheim proben. Daher haben wir uns dazu entschieden, draußen an verschiedenen Orten der Gemeinde zu proben.",
    "bilder20Sub4Headline": "Herbstfestle",
    "bilder20Sub4Des": "Unser Herbstfest war 2020 unter Coronabedingungen deutlich kleiner und mit vielen Hygienevorgaben eingeschränkt, dennoch war es gut besucht.",
    "bilder19Headline": "Bilder 2019",
    "bilder19Sub1Headline": "Probenwochenende",
    "bilder19Sub1Des": "Unsere Jugendkapelle ist zu Beginn des Jahres zur Vorbereitung des anstehenden Wertungsspiels nach Blaubeuren in die Jugendherberge gefahren, um dort ein Wochenende lang intensiv zu proben. Neben Gesamtproben wurden auch sehr erfolgreiche Registerproben durchgeführt. Zzwischendurch wurde noch ein Ausflug in den Trampolinpark unternommen, der auch die Kammeradschaft zwischen den Jugendlichen gefördert hat.",
    "bilder19Sub2Headline": "Maikonzert",
    "bilder19Sub2Des": "Am 1. Mai hat der Schwäbische Albverein Gingen auch 2019 einen Maibaum vor dem Rathaus gestellt. Wir haben das Fest anschließend musikalisch umrahmt. Sowohl die Jugendgruppe und Jugendkapelle als auch die Aktive Kapelle haben zur Unterhaltung aufgespielt.",
    "bilder19Sub3Headline": "Wertungsspiel",
    "bilder19Sub3Des": "Unsere Jugendkapelle hat im März am Jugendwertungsspiel in Altheim/Staig im Kreisverband Ulm/Alb-Donau teilgenommen. Mit den Titeln \"The Knight's Castle\" und \"Ghost, Goblins and Witches\" haben sie ein sehr gutes Ergebnis erzielt.",
    "bilder19Sub4Headline": "Böhmenkirch",
    "bilder19Sub4Des": "Anfang August waren wir beim Musikverein Frisch-Auf Böhmenkirch bei deren Fest unter den Linden zu Gast. Zur Mittagszeit haben wir für eine passende gemütliche Unterhaltung gesorgt.",
    "bilder19Sub5Headline": "Herbstfest",
    "bilder19Sub5Des": "Zum Start in den Herbst fand rund um unser Musikerheim unser Herbstfest statt. Vormittags spielte \"Just Music\" zum Weißwurstfrühshoppen. Mit Saxophon-, Trompeten- und Flötenpower spielte die Gingener Band eine bunte Mischung aus Rock & Pop, Jazz, Dixie und mehr. Zum Mittag füllte sich der Garten und nur noch wenige Plätze waren frei. Das angenehme Wetter lud zu einem gemütlichen Nachmittag im Garten ein und so war das Fest bis Abends gut besucht, während unsere Jugend sowie die Aktive Kapelle für gute Unterhaltung sorgten.",
    //Sponsors
    "sponsorsHeadline": "Unsere Sponsoren",
    "sponsorsSideInfoP1": "Um gemeinsam musizieren zu können, sind finanzielle Mittel in nicht unerheblicher Höhe notwendig. Insbesondere die Ausbildung von Jungmusikern ist mit hohen Kosten verbunden. Aber auch Noten, Dirigent und Dozenten sowie die Probenräumlichkeit, unser Musikerheim, müssen finanziert werden.",
    "sponsorsSideInfoP2": "Wir ermöglichen Kindern und Jugendlichen tiefer in die Welt der Musik einzusteigen und ein Instrument zu erlernen. Gerade auch dafür erhalten wir von Unternehmen im Ort und aus der Region finanzielle Unterstützung. Dafür möchten wir uns recht herzlich Bedanken!",
    "sponsorsP1": "Möchten auch Sie bzw. Ihr Unternehmen uns untersützen, wenden Sie sich bitte an unseren <a href='/kontakt.html'>Sponsoringbeauftragten Wolf-Dieter Fauth</a>.",

    "loremipsum": "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
}

$(document).ready(function() {
    resizeSideLogo();
    initAOS();
    setTimeout(resizeSideLogo, 300);
    checkGridSizeClass();
    if ($(".fullCalendar") != null) {
        initFullCalendar();
        loadEventsTmp();
    }
    stickFooterToBottom();
    addGalleryClickListener();
})

$(window).on('resize', function() {
    resizeSideLogo();
    initAOS();
    checkGridSizeClass();
})


// import string resources
var resElements = document.querySelectorAll("[data-res]");
for (var i = 0; i < resElements.length; i++) {
    var resElement = resElements[i];
    var resKey = resElement.getAttribute("data-res");
    if (resKey) {
        for (var key in resources) {
            if (key.indexOf(resKey) == 0) {
                resElement.innerHTML = resources[key];
            }
        }
    }
}

// navigation handling
var changedNav = false;
if (document.body.scrollTop === 0) {
    $("#mainMenuRight a").css('color', 'black');
    $(".top-bar").css('background-color', 'white');
    $(".top-bar ul").css('background-color', 'white');
    $(".top-bar").removeClass('lightShadow');
    $(".top-bar .is-dropdown-submenu-parent > a").addClass('black');
    $("#mainMenuLogo").addClass('gone');
    changedNav = true;
}
$("#contentContainer").scroll(function() {
    libs.AOS.refresh();
    if ($("#contentContainer").scrollTop() <= 20) {
        changeMenuToUnscrolled();
        changedNav = true;
    }
    if ($("#contentContainer").scrollTop() > 30 && changedNav) {
        changeMenuToScrolled();
        changedNav = false;
    }
})

// click listener
$('.instrumentCard').click(function() {
    addInstrumentInfoText($(this).attr('data-instrument'), $(this));
});

// SVG Injector
// Elements to inject
var mySVGsToInject = document.querySelectorAll('img.inject-me');

// Options
var injectorOptions = {
  evalScripts: 'once',
  pngFallback: 'assets/png'
};

var afterAllInjectionsFinishedCallback = function (totalSVGsInjected) {
  // Callback after all SVGs are injected
  console.log('We injected ' + totalSVGsInjected + ' SVG(s)!');
};

var perInjectionCallback = function (svg) {
  // Callback after each SVG is injected
  console.log('SVG injected: ' + svg);
};

// create injector configured by options
var injector = new libs.svgInjector(injectorOptions);

// Trigger the injection
injector.inject(
  mySVGsToInject,
  afterAllInjectionsFinishedCallback,
  perInjectionCallback
);

// slick carousel
$(".content-carousel").slick({
  // normal options...
  speed: 5000,
	autoplay: true,
	autoplaySpeed: 0,
	cssEase: 'linear',
  slidesToShow: 5,
	slidesToScroll: 1,
  infinite: true,
  swipeToSlide: true,
	centerMode: true,
  focusOnSelect: true,
  // the magic
  responsive: [{
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        infinite: true
      }
    }, {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        dots: true
      }
    }, {
      breakpoint: 300,
      settings: "unslick" // destroys slick
    }]
});

// tablesaw table plugin
$(function () {
  $(document)
    .foundation()
    .trigger('enhance.tablesaw');
});

var TablesawConfig = {
  swipeHorizontalThreshold: 15
};

// app dashboard toggle
$('[data-app-dashboard-toggle-shrink]').on('click', function(e) {
  e.preventDefault();
  $(this).parents('.app-dashboard').toggleClass('shrink-medium').toggleClass('shrink-large');
});

function changeMenuToScrolled() {
    $(".top-bar").removeClass('menuBackgroundToWhite').addClass('menuBackgroundToColor');
    $(".top-bar ul").removeClass('menuBackgroundToWhite').addClass('menuBackgroundToColor');
    $("#mainMenuRight a").removeClass('menuColorToWhite').addClass('menuColorToColor');
    $(".top-bar").addClass('lightShadow');

    $(".top-bar .is-dropdown-submenu-parent > a").removeClass('black');

    $("#mainMenuLogo").removeClass('gone');
    $("#sideImgLogo").addClass('transparent');
}

function changeMenuToUnscrolled() {
    $(".top-bar").removeClass('menuBackgroundToColor').addClass('menuBackgroundToWhite');
    $(".top-bar ul").removeClass('menuBackgroundToColor').addClass('menuBackgroundToWhite');
    $("#mainMenuRight a").removeClass('menuColorToColor').addClass('menuColorToWhite');
    $(".top-bar").removeClass('lightShadow');
    
    $(".top-bar .is-dropdown-submenu-parent > a").addClass('black');

    $("#mainMenuLogo").addClass('gone');
    $("#sideImgLogo").removeClass('transparent');
}

function resizeSideLogo() {
    var percentageOfImg = 0.5;
    var heightSideImg = $("#sideImg").height();
    var widthSideImg = $("#sideImg").width();
    $("#sideImgLogo").height(heightSideImg * percentageOfImg)
        .css("margin-top", (heightSideImg - (heightSideImg * percentageOfImg)) / 2)
        .css("margin-left", widthSideImg * 0.05);
}

function stickFooterToBottom() {
    var footer = $("footer");
    var distanceToBottom = window.innerHeight - footer.offset().top - footer.outerHeight();
    if (distanceToBottom > 0) {
        footer.css("margin-top", parseInt(footer.css("margin-top")) + distanceToBottom);
    }
}

function initAOS() {
    libs.AOS.init({
        disable: 'mobile', 

        easing: 'ease',
        once: true,
        mirror: false,
    });
}

function checkGridSizeClass() {
    if (window.innerWidth >= 1024) {
        //large
        recalcDelaysAOS(3);
        setNewsCardAlign(3);
    } else if (window.innerWidth < 640) {
        //small
        recalcDelaysAOS(1);
        setNewsCardAlign(1);
    } else {
        //medium
        recalcDelaysAOS(2);
        setNewsCardAlign(2);
    }
}

function recalcDelaysAOS(rowCount) {
    $(".aosAnimatedCard").each(function(index) {
        $(this).attr("data-aos-delay", 250 * (index % rowCount));
    });
}

function setNewsCardAlign(rowCount) {
    $(".newsCardTextfield").each(function(index) {
        $(this).removeClass("newsCardCenter").removeClass("newsCardRight").removeClass("newsCardLeft");
        switch (rowCount) {
            case 1:
                $(this).addClass("newsCardCenter");
                break;
            case 2:
                if (index % rowCount == 0) {
                    $(this).addClass("newsCardRight");
                } else {
                    $(this).addClass("newsCardLeft");
                }
                break;
            case 3:
                switch (index % rowCount) {
                    case 0: 
                        $(this).addClass("newsCardRight");
                        break;
                    case 1:
                        $(this).addClass("newsCardCenter");
                        break;
                    case 2:
                        $(this).addClass("newsCardLeft");
                }
        }
    });
}

function initFullCalendar() {
    $("#calendar").fullCalendar({
        locale: 'de',
        timeFormat: 'HH:mm',
        defaultView: 'listYear',
        header: {
            left: 'prev,next today',
            center: 'title',
            right: 'month, listYear'
        },
        buttonText: {
            today: 'Heute',
            month: 'Monat',
            listYear: 'Liste'
        },
        dayNames: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
        dayNamesShort: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa']
    });
}

function loadEventsTmp() {

    var ev = {
        events: [
            {
                title: 'Herbstfest',
                start: '2018-09-23T10:00:00.00Z',
                end: '2018-09-23T18:00:00.00Z'
            },
            {
                title: 'Adventstreff',
                start: '2018-12-01T15:15:00.00Z',
                end: '2018-12-01T16:00:00.00Z'
            },
            {
                title: 'Jahresfeier',
                start: '2018-12-08T19:30:00.00Z',
                end: '2018-12-09T01:30:00.00Z'
            },
            {
                title: 'Hauptversammlung',
                start: '2019-03-09T19:30:00.00Z',
                end: '2019-03-09T21:30:00.00Z'
            },
            {
                title: 'Frühjahrskonzert',
                start: '2019-03-23T19:00:00.00Z',
                end: '2019-03-23T21:00:00.00Z'
            },
            {
                title: 'Schnapperfest',
                start: '2019-06-29T11:00:00.00Z',
                end: '2019-06-30T00:30:00.00Z',
                url: './schnapperfest.html'
            },
            {
                title: 'Filstalmusikerringtreffen Heiningen/Eschenbach',
                start: '2019-06-30T12:00:00.00Z',
                end: '2019-06-30T17:00:00.00Z'
            },
            {
                title: 'Sommerserenade',
                start: '2019-07-05T18:00:00.00Z',
                end: '2019-07-05T22:00:00.00Z'
            },
            {
                title: 'Stadtfest Eislingen',
                start: '2019-07-06T11:00:00.00Z',
                end: '2019-07-06T13:00:00.00Z'
            },
            {
                title: 'Kozert Projektorchester',
                start: '2019-07-12T20:00:00.00Z',
                end: '2019-07-12T22:00:00.00Z'
            },
            {
                title: 'Grillfest Jugend',
                start: '2019-07-13T14:00:00.00Z',
                end: '2019-07-13T18:00:00.00Z'
            },
            {
                title: 'Stadtfest Süßen',
                start: '2019-07-14T16:00:00.00Z',
                end: '2019-07-14T19:00:00.00Z'
            },
            {
                title: 'Schulfest Hohensteinschule',
                start: '2019-07-19T16:00:00.00Z',
                end: '2019-07-19T16:30:00.00Z'
            },
            {
                title: 'Saisonabschluss',
                start: '2019-07-27T10:00:00.00Z',
                end: '2019-07-27T23:00:00.00Z'
            },
            {
                title: 'Fest unter den Linden Böhmenkirch',
                start: '2019-08-04T11:30:00.00Z',
                end: '2019-08-04T14:00:00.00Z'
            },
            {
                title: 'Schnapperdörfle Fest',
                start: '2019-08-16T18:00:00.00Z',
                end: '2019-08-16T19:00:00.00Z'
            },
            {
                title: 'Altpapiersammlung',
                start: '2019-09-07T09:00:00.00Z',
                end: '2019-09-07T14:00:00.00Z'
            },
            {
                title: 'Herbstfest',
                start: '2019-09-22T09:00:00.00Z',
                end: '2019-09-22T20:00:00.00Z',
                url: './herbstfest.html'
            },
            {
                title: 'TB Jahresfeier',
                start: '2019-11-16T18:00:00.00Z',
                end: '2019-11-17T02:00:00.00Z'
            },
            {
                title: 'Volkstrauertag',
                start: '2019-11-17T11:00:00.00Z',
                end: '2019-11-17T12:00:00.00Z'
            },
            {
                title: 'Adventstreff',
                start: '2019-11-30T10:00:00.00Z',
                end: '2019-11-30T16:00:00.00Z'
            },
            {
                title: 'Jahresfeier',
                start: '2019-12-07T19:00:00.00Z',
                end: '2019-12-08T03:00:00.00Z'
            },
            {
                title: 'Weihnachtsblasen',
                start: '2019-12-24T12:00:00.00Z',
                end: '2019-12-24T16:00:00.00Z'
            },
            {
                title: 'Wanderung Heilige 3 Könige',
                start: '2020-01-06',
                end: '2020-01-07'
            },
            {
                title: 'Probenwochenende',
                start: '2020-01-31T18:00:00.00Z',
                end: '2020-02-02T18:00:00.00Z'
            },
            {
                title: 'Hauptversammlung Blasmusik-KV',
                start: '2020-02-08',
                end: '2020-02-09'
            },
            {
                title: 'Schnapperfasnet',
                start: '2020-02-08',
                end: '2020-02-09'
            },
            {
                title: 'Hauptversammlung',
                start: '2020-03-14',
                end: '2020-03-15'
            },
            {
                title: 'Frühjahrskonzert',
                start: '2020-04-04T19:00:00.00Z',
                end: '2020-04-04T22:00:00.00Z'
            },
            {
                title: 'Karfreitagswanderung',
                start: '2020-04-10',
                end: '2020-04-11'
            },
            {
                title: 'Konzert in Wangen',
                start: '2020-04-26T19:00:00.00Z',
                end: '2020-04-26T22:30:00.00Z'
            },
            {
                title: 'Platzkonzert 1.Mai',
                start: '2020-05-01T11:00:00.00Z',
                end: '2020-05-01T13:00:00.00Z'
            },
            {
                title: 'TB Musiknacht',
                start: '2020-05-09',
                end: '2020-05-10'
            },
            {
                title: 'Kinderfest Kuchen',
                start: '2020-05-16T13:00:00.00Z',
                end: '2020-05-16T15:00:00.00Z'
            },
            {
                title: 'Himmelfahrtshock Hattenhofen',
                start: '2020-05-21T14:00:00.00Z',
                end: '2020-05-21T17:00:00.00Z'
            },
            {
                title: 'Musikerhock',
                start: '2020-06-14',
                end: '2020-06-15'
            },
            {
                title: 'NWZ Blasmusikfestival',
                start: '2020-06-18',
                end: '2020-06-19'
            },
            {
                title: 'Festwochenende TB Gingen',
                start: '2020-06-26',
                end: '2020-06-29'
            },
            {
                title: 'Sommerserenade',
                start: '2020-07-03T19:00:00.00Z',
                end: '2020-07-03T23:30:00.00Z'
            },
            {
                title: 'Promenadenkonzert Kurpark Bad Boll',
                start: '2020-07-05T14:30:00.00Z',
                end: '2020-07-05T15:30:00.00Z'
            },
            {
                title: 'Filstalmusikerringtreffen Salach',
                start: '2020-07-19T13:00:00.00Z',
                end: '2020-07-19T20:00:00.00Z'
            },
            {
                title: 'D-Lehrgänge',
                start: '2020-07-31',
                end: '2020-08-08'
            },
            {
                title: 'Grillfest Aktive Kapelle',
                start: '2020-08-01',
                end: '2020-08-02'
            },
            {
                title: 'Fest unter den Linden Böhmenkirch',
                start: '2020-08-09T14:30:00.00Z',
                end: '2020-08-09T17:00:00.00Z'
            },
            {
                title: 'Altpapiersammlung',
                start: '2020-09-12',
                end: '2020-09-13'
            },
            {
                title: 'Herbstfest',
                start: '2020-09-27',
                end: '2020-09-28'
            },
            {
                title: 'TB Jahresfeier Bewirtschaftung',
                start: '2020-11-14',
                end: '2020-11-15'
            },
            {
                title: 'Volkstrauertag',
                start: '2020-11-15',
                end: '2020-11-16'
            },
            {
                title: 'Adventstreff',
                start: '2020-11-28',
                end: '2020-11-29'
            },
            {
                title: 'Jahresfeier',
                start: '2020-12-05T19:30:00.00Z',
                end: '2020-12-06T03:00:00.00Z'
            },
            {
                title: 'Weihnachtsblasen',
                start: '2020-12-24',
                end: '2020-12-25'
            },
            {
                title: 'Hauptversammlung Blasmusik-Kreisverband GP',
                start: '2021-02-06',
                end: '2021-02-07'
            },
            {
                title: 'Hauptversammlung',
                start: '2021-03-13T19:30:00.00Z',
                end: '2021-03-13T21:30:00.00Z'
            },
            {
                title: 'Wanderung Karfreitag',
                start: '2021-04-02',
                end: '2021-04-03'
            },
            {
                title: 'Konzert',
                start: '2021-04-17T19:00:00.00Z',
                end: '2021-04-17T22:00:00.00Z'
            },
            {
                title: 'Konzert in Wangen',
                start: '2021-04-24',
                end: '2021-04-25'
            },
            {
                title: 'Platzkonzert Maibaumaufstellung',
                start: '2021-05-01T11:00:00.00Z',
                end: '2021-05-01T13:00:00.00Z'
            },
            {
                title: 'TB Musiknacht',
                start: '2021-05-09',
                end: '2021-05-10'
            },
            {
                title: 'Himmelfahrtshock Hattenhofen',
                start: '2021-05-13T14:00:00.00Z',
                end: '2021-05-13T17:00:00.00Z'
            },
            {
                title: 'Fronleichnamsgottesdienst mit Prozession',
                start: '2021-06-03',
                end: '2021-06-04'
            },
            {
                title: 'Musikerhock',
                start: '2021-06-13',
                end: '2021-06-14'
            },
            {
                title: 'Festwochenende TB Gingen',
                start: '2021-06-25',
                end: '2021-06-28'
            },
            {
                title: 'Sommerserenade',
                start: '2021-07-09T19:00:00.00Z',
                end: '2021-07-09T23:00:00.00Z'
            },
            {
                title: 'D-Lehrgänge',
                start: '2021-07-30',
                end: '2021-08-07'
            },
            {
                title: 'Grillfest Aktive Kapelle',
                start: '2021-07-31',
                end: '2021-08-01'
            },
            {
                title: 'Fest unter den Linden Böhmenkirch',
                start: '2021-08-01',
                end: '2021-08-02'
            },
            {
                title: 'Altpapiersammlung',
                start: '2021-09-11',
                end: '2021-09-12'
            },
            {
                title: 'Promenadenkonzert Bad Boll',
                start: '2021-09-19T14:30:00.00Z',
                end: '2021-09-19T15:30:00.00Z'
            },
            {
                title: 'Herbstfest',
                start: '2021-09-26',
                end: '2021-09-27'
            },
            {
                title: 'Bewirtschaftung TB Jahresfeier',
                start: '2021-11-13',
                end: '2021-11-14'
            },
            {
                title: 'Volkstrauertag',
                start: '2021-11-14',
                end: '2021-11-15'
            },
            {
                title: 'Adventstreff',
                start: '2021-11-27',
                end: '2021-11-28'
            },
            {
                title: 'Weihnachtsblasen',
                start: '2021-12-24',
                end: '2021-12-25'
            },
            {
                title: 'Hauptversammlung Kreisverband GP',
                start: '2022-02-12',
                end: '2022-02-13'
            },
            {
                title: 'Hauptversammlung',
                start: '2022-03-12T19:30:00.00Z',
                end: '2022-03-12T21:30:00.00Z'
            },
            {
                title: 'Konzert',
                start: '2022-04-09',
                end: '2022-04-10'
            },
            {
                title: 'Karfreitagswanderung',
                start: '2022-04-15',
                end: '2022-04-16'
            },
            {
                title: 'Platzkonzert',
                start: '2022-05-01',
                end: '2022-05-02'
            },
            {
                title: 'Himmelsfahrthock Hattenhofen',
                start: '2022-05-26T11:30:00.00Z',
                end: '2022-05-26T14:30:00.00Z'
            },
            {
                title: 'Musikerhock',
                start: '2022-06-12',
                end: '2022-06-13'
            },
            {
                title: 'Kinderfest Gingen',
                start: '2022-06-24',
                end: '2022-06-27'
            },
            {
                title: 'Landesmusikfestival GP',
                start: '2022-07-02',
                end: '2022-07-04'
            },
            {
                title: 'Sommerserenade',
                start: '2022-07-08T19:00:00.00Z',
                end: '2022-07-08T22:00:00.00Z'
            },
            {
                title: 'D-Lehrgänge',
                start: '2022-07-29',
                end: '2022-08-06'
            },
            {
                title: 'Grillfest',
                start: '2022-07-29',
                end: '2022-07-31'
            },
            {
                title: 'Fest unter den Linden Böhmenkirch',
                start: '2022-08-07',
                end: '2022-08-08'
            },
            {
                title: 'Altpapiersammlung',
                start: '2022-09-10',
                end: '2022-09-11'
            },
            {
                title: 'Promenadenkonzert Bad Boll',
                start: '2022-09-18T14:30:00.00',
                end: '2022-09-18T15:30:00.00'
            },
            {
                title: 'Herbstfest',
                start: '2022-09-25',
                end: '2022-09-26'
            },
            {
                title: 'TB Jahresfeier Bewirtschaftung',
                start: '2022-11-12',
                end: '2022-11-13'
            },
            {
                title: 'Volkstrauertag',
                start: '2022-11-13',
                end: '2022-11-14'
            },
            {
                title: 'Adventstreff',
                start: '2022-11-26',
                end: '2022-11-27'
            },
            {
                title: 'Jahresfeier',
                start: '2022-12-03',
                end: '2022-12-04'
            },
            {
                title: 'Weihnachtsblasen',
                start: '2022-12-24',
                end: '2022-12-25'
            },
            {
                title: 'Hauptversammlung',
                start: '2023-03-11T19:00:00.00Z',
                end: '2023-03-11T21:00:00.00Z'
            },
            {
                title: 'Hochzeit',
                start: '2023-04-22',
                end: '2023-04-22'
            },
            {
                title: 'Frühjahrskonzert Wangen',
                start: '2023-04-23',
                end: '2023-04-24'
            },
            {
                title: 'Platzkonzert 1.Mai',
                start: '2023-05-01',
                end: '2023-05-02'
            },
            {
                title: 'Marschmusikworkshop',
                start: '2023-05-13',
                end: '2023-05-14'
            },
            {
                title: 'Straßenfest Schnittlingen',
                start: '2023-05-18T15:45:00.00Z',
                end: '2023-05-18T19:15:00.00Z'
            },
            {
                title: 'Fronleichnam',
                start: '2023-06-08',
                end: '2023-06-09'
            },
            {
                title: 'Musikerhock',
                start: '2023-06-11',
                end: '2023-06-12'
            },
            {
                title: 'Schnapperfest',
                start: '2023-06-24',
                end: '2023-06-25'
            },
            {
                title: 'Polterabend',
                start: '2023-07-01',
                end: '2023-07-02'
            },
            {
                title: 'Stadtfest Eislingen',
                start: '2023-07-01T14:00:00.00Z',
                end: '2023-07-01T15:00:00.00Z'
            },
            {
                title: 'Sommerserenade',
                start: '2023-07-07',
                end: '2023-07-08'
            },
            {
                title: 'Tag der offenen Tür Landratsamt',
                start: '2023-07-08T15:30:00.00Z',
                end: '2023-07-08T16:30:00.00Z'
            },
            {
                title: 'Wäschenbeuren 100 Jahre MV',
                start: '2023-07-16T11:00:00.00Z',
                end: '2023-07-16T16:00:00.00Z'
            },
            {
                title: 'Saisonabschluss',
                start: '2023-07-28',
                end: '2023-07-29'
            },
            {
                title: 'Waldfest Haiming',
                start: '2023-08-05',
                end: '2023-08-07'
            },
            {
                title: 'Schnapperdörfle',
                start: '2023-08-11',
                end: '2023-08-12'
            },
            {
                title: 'Hochzeit',
                start: '2023-08-18',
                end: '2023-08-19'
            },
            {
                title: 'Altpapiersammlung',
                start: '2023-09-09',
                end: '2023-09-10'
            },
            {
                title: 'Herbstfest',
                start: '2023-09-24',
                end: '2023-09-25'
            },
            {
                title: 'Hochzeit',
                start: '2023-10-07',
                end: '2023-10-08'
            },
            {
                title: 'Oktoberfest Sontheim',
                start: '2023-10-22T11:00:00.00Z',
                end: '2023-10-22T14:00:00.00Z'
            },
            {
                title: 'Volkstrauertag',
                start: '2023-11-19',
                end: '2023-11-20'
            },
            {
                title: 'Adventstreff',
                start: '2023-12-02',
                end: '2023-12-03'
            },
            {
                title: 'Jahresfeier',
                start: '2023-12-09',
                end: '2023-12-10'
            },
            {
                title: 'Weihnachtsspielen',
                start: '2023-12-24',
                end: '2023-12-25'
            },
            {
                title: 'Hauptversammlung',
                start: '2024-03-09T19:30:00.00Z',
                end: '2024-03-09T21:00:00.00Z'
            },
            {
                title: 'Frühjahrskonzert',
                start: '2024-04-13T19:00:00.00Z',
                end: '2024-04-13T21:00:00.00Z'
            },
            {
                title: 'Platzkonzert 1.Mai',
                start: '2024-05-01',
                end: '2024-05-02'
            },
            {
                title: 'Straßenfest Schnittlingen',
                start: '2024-05-09T15:45:00.00Z',
                end: '2024-05-09T19:15:00.00Z'
            },
            {
                title: 'Umzug Maientag Göppingen',
                start: '2024-06-15T10:00:00.00Z',
                end: '2024-06-15T13:00:00.00Z'
            },
            {
                title: 'Musikerhock',
                start: '2024-06-16',
                end: '2024-06-17'
            },
            {
                title: 'Schnapperfest',
                start: '2024-06-29',
                end: '2024-06-30'
            },
            {
                title: 'Sommerserenade',
                start: '2024-07-05',
                end: '2024-07-06'
            },
            {
                title: 'Kinder-Mitmachkonzert',
                start: '2024-07-14',
                end: '2024-07-15'
            },
            {
                title: 'Schnapperdörfle',
                start: '2024-08-09',
                end: '2024-08-10'
            },
            {
                title: 'Altpapiersammlung',
                start: '2024-09-14',
                end: '2024-09-15'
            },
            {
                title: 'Kurkonzert Bad Boll',
                start: '2024-09-15',
                end: '2024-09-16'
            },
            {
                title: 'Herbstfest',
                start: '2024-09-22',
                end: '2024-09-23'
            },
            {
                title: 'Volkstrauertag',
                start: '2024-11-17',
                end: '2024-11-18'
            },
            {
                title: 'Konzert Heeresmusikkorps Ulm',
                start: '2024-11-19',
                end: '2024-11-20'
            },
            {
                title: 'Adventstreff',
                start: '2024-11-30',
                end: '2024-12-01'
            },
            {
                title: 'Jahresfeier',
                start: '2024-12-07',
                end: '2024-12-08'
            },
            {
                title: 'Weihnachtsspielen',
                start: '2024-12-24',
                end: '2024-12-25'
            }
        ]
    }

    $('#calendar').fullCalendar('addEventSource', ev)
    $('#calendar').fullCalendar('rerenderEvents');
}


function loadEvents() {
    $.get("../assets/events.ics", function(result) {
        var cal, icalevents, dtstart, dtend, StrStart, StrEnd, StrID, StrAllDay, StrTitle, StrURL, StrLocation, StrDescription;
        $.each(result, function(key, val) {
            try {
                cal = $.parseIcs(val.url, AEFC.urls.externalfile);
                Alertify.log.success('ICS '+val.url);
                icalevents = [];

                for (i = 0; i < cal.event.length; i = i + 1) {
                    dtstart = cal.event[i].dtstart[0].value;
                    StrStart = dtstart.substring(0, 4) + '-' + dtstart.substring(4, 6) + '-' + dtstart.substring(6, 8);
                    if (dtstart.substring(9, 11) !== '') {
                        StrStart = StrStart + 'T' + dtstart.substring(9, 11) + '-' + dtstart.substring(11, 13) + '-' + dtstart.substring(13, 15);
                    }

                    dtend = cal.event[i].dtend[0].value;
                    StrEnd = dtend.substring(0, 4) + '-' + dtend.substring(4, 6) + '-' + dtend.substring(6, 8);
                    if (dtend.substring(9, 11) !== '') {
                        StrEnd = StrEnd + 'T' + dtend.substring(9, 11) + '-' + dtend.substring(11, 13) + '-' + dtend.substring(13, 15);
                    }

                    StrAllDay = StrStart.indexOf("T") === -1;

                    if (cal.event[i].uid !== "undefined") { StrID = 'ICS'+AEFC.nbcalendars+'#'+cal.event[i].uid[0].value
                        ;} 
                    if (cal.event[i].summary !== "undefined") { StrTitle = cal.event[i].summary[0].value;}

                    if (cal.event[i].url !== "undefined") { StrURL = cal.event[i].url[0].value;}

                    if (cal.event[i].location!== "undefined") { StrLocation = cal.event[i].location[0].value;}

                    if (cal.event[i].description !== "undefined") { StrDescription = cal.event[i].description[0].value;}


                    icalevents.push({
                        id: StrID,
                        title: StrTitle,
                        url: StrURL,
                        start: StrStart,
                        end: StrEnd,
                        allDay: StrAllDay,
                        location: StrLocation,
                        description: StrDescription,
                        editable:false,
                        className: val.className,
                        color: val.color,
                        textColor: val.textColor
                    });
                }
                AEFC.source[AEFC.nbcalendars] = {
                    events: icalevents
                    //className: val.className,
                    //color: val.color,
                    //textColor: val.textColor
                };
            } catch (err) {
                Alertify.log.error("ERROR loading (" + val.url + "): "+err);
            }
        });
    });
}

function addGalleryClickListener() {
    $(".galleryImage").click(function() {
        $(this).addClass("galleryImageClicked");
        $(this).children("img").css("max-width", $(window).width());
        $(this).children("img").css("max-height", $(window).height());
        var paddingWidth = ($(window).width() - $(this).children("img").width()) / 2;
        var paddingHeight = ($(window).height() - $(this).children("img").height()) / 2;
        $(this).css("padding-left", paddingWidth);
        $(this).css("padding-right", paddingWidth);
        $(this).css("padding-top", paddingHeight);
        $(this).css("padding-bottom", paddingHeight);
        $("#pictureControl").removeClass("gone");

        $("#pictureControlLeft").click(function() {
            var currentlyClickedImage = $(".galleryImageClicked");
            currentlyClickedImage.removeClass("galleryImageClicked");
            currentlyClickedImage.children("img").css("max-width", "");
            currentlyClickedImage.children("img").css("max-height", "");
            currentlyClickedImage.css("padding", 0);
            $(".galleryImage").unbind();
            $("#pictureControlLeft").unbind();
            $("#pictureControlRight").unbind();
            $("#pictureControl").addClass("gone");
            addGalleryClickListener();
            currentlyClickedImage.prev().click();
        });

        $("#pictureControlRight").click(function() {
            var currentlyClickedImage = $(".galleryImageClicked");
            currentlyClickedImage.removeClass("galleryImageClicked");
            currentlyClickedImage.children("img").css("max-width", "");
            currentlyClickedImage.children("img").css("max-height", "");
            currentlyClickedImage.css("padding", 0);
            $(".galleryImage").unbind();
            $("#pictureControlLeft").unbind();
            $("#pictureControlRight").unbind();
            $("#pictureControl").addClass("gone");
            addGalleryClickListener();
            currentlyClickedImage.next().click();
        });

        $(".galleryImageClicked").click(function() {
            $(this).removeClass("galleryImageClicked");
            $(this).children("img").css("max-width", "");
            $(this).children("img").css("max-height", "");
            $(this).css("padding", 0);
            $(".galleryImage").unbind();
            $("#pictureControlLeft").unbind();
            $("#pictureControlRight").unbind();
            $("#pictureControl").addClass("gone");
            addGalleryClickListener();
        });
    });
}

$.wait = function( callback, milliseconds){
    return window.setTimeout( callback, milliseconds );
}

function addInstrumentInfoText(instrument, clickedElement) {
    if ($('#instrumentInfoTextHolder').attr('data-instrument') == instrument) {
        $('#instrumentInfoTextHolder').toggleClass('instrumentInfoTextFull');
        clickedElement.children('.instrumentCardImg').toggleClass('instrumentCardImgBlue');
        $.wait(function() {
            $('#instrumentInfoTextHolder').removeAttr('data-instrument').addClass('hide').html('').removeClass('instrumentInfoText');
        }, 500);
    } else {
        if ($('#instrumentInfoTextHolder').hasClass('instrumentInfoText')) {
            $('#instrumentInfoTextHolder').attr('data-instrument', instrument).html(resources['jugendAusbildungInstrumente' + instrument + 'InfoText']);
            removeAllInstrumentBackground();
            clickedElement.children('.instrumentCardImg').toggleClass('instrumentCardImgBlue');
        } else {
            $('#instrumentInfoTextHolder').removeAttr('data-instrument').attr('data-instrument', instrument).html(resources['jugendAusbildungInstrumente' + instrument + 'InfoText']).removeClass('hide').addClass('instrumentInfoText');
            clickedElement.children('.instrumentCardImg').toggleClass('instrumentCardImgBlue');
            $.wait(function() {
                $('#instrumentInfoTextHolder').toggleClass('instrumentInfoTextFull');
            }, 100);
        }
    }
}

function removeAllInstrumentBackground() {
    $('.instrumentCardImg').removeClass('instrumentCardImgBlue');
}